import { NavigateFunction } from "react-router-dom";
import { routes } from "@/pages/routes";
import { isValidOauthAuthorizeUrl } from "./isOauthAuthorizeUrl";
import { PARAM_RETURN_URL } from "@/constants/messages";

/**
 * Перенаправляет пользователя на сохранённый OAuth URL или на профиль.
 *
 * @param storedReturnUrl - URL для перенаправления, сохранённый в хранилище.
 * @param navigate - Функция навигации из React Router.
 * @returns {boolean} - Возвращает true, если выполнено перенаправление на сохранённый URL, иначе false.
 */
export const redirectToStoredUrlOrProfile = (
  navigate: NavigateFunction,
): boolean => {
  const storedReturnUrl = localStorage.getItem(PARAM_RETURN_URL);

  if (storedReturnUrl && isValidOauthAuthorizeUrl(storedReturnUrl)) {
    try {
      const url = new URL(storedReturnUrl);
      const pathname = url.pathname;
      const search = url.search;

      navigate(`${pathname}${search}`, { replace: true });

      // Очистка сохранённого URL после перенаправления, чтобы избежать повторного перенаправления
      localStorage.removeItem(PARAM_RETURN_URL);
      return true;
    } catch (error) {
      console.error("Ошибка при разборе сохранённого URL:");
    }
  }

  // Перенаправление на профиль, если сохранённый URL отсутствует или недействителен
  navigate(routes.profile, { replace: true });
  return false;
};
