import { errorSlice } from "@/store/error";
import { store } from "@/store/configureStore";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { confirmPhoneUrl } from "@/endpoints/apiUrl";
import { CustomResponse } from "@/services/apiFetch.type";
import { apiFetch, callApiFn, FetchError } from "@/services/apiFetch";
import { FormSchemaConfirmPhoneByCodeModal } from "@/constants/validateSchema";

export const confirmPhoneByCodeThunk = createAsyncThunk(
  "user/confirmPhoneByCode",
  async ({ data }: { data: FormSchemaConfirmPhoneByCodeModal }) => {
    try {
      const response = await callApiFn<CustomResponse<undefined>>(() =>
        apiFetch({
          url: `${confirmPhoneUrl}`,
          options: {
            method: "POST",
            body: JSON.stringify(data),
          },
        }),
      );

      // data пустая
      if (response.success) {
        return true;
      } else {
        throw response;
      }
    } catch (e: FetchError | any) {
      store.dispatch(errorSlice.actions.setCustomError(e.errors));
    }
  },
);
