import { ReactNode } from "react";
import * as Sentry from "@sentry/react";

const release = import.meta.env.VITE_SENTRY_RELEASE;
const env = import.meta.env.CI_ENVIRONMENT_NAME;

if (env === "prod") {
  Sentry.init({
    dsn: "https://56c916f0c901a68cf01dc0389a5a36de@sentry.prodamus.info/4",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    release,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

interface SentryContainerProps {
  children: ReactNode;
}

export const SentryContainer = ({ children }: SentryContainerProps) => {
  return <>{children}</>;
};
