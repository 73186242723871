import { NavigateFunction } from "react-router";

import { IAuth } from "@/store/auth";
import { routes } from "@/pages/routes";
import { errorSlice } from "@/store/error";
import { store } from "@/store/configureStore";
import { otpRequestUrl } from "@/endpoints/apiUrl";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { CustomResponse } from "@/services/apiFetch.type";
import { apiFetch, callApiFn, FetchError } from "@/services/apiFetch";
import { FormSchemaOtpRequestPage } from "@/constants/validateSchema";
import { validateUrlProtocol } from "@/utils/validateUrlProtocol";
import { validateUrlOrigin } from "@/utils/validateUrlOrigin";

export const otpRequestThunk = createAsyncThunk(
  "auth/otpRequest",
  async ({
    data,
    navigate,
  }: {
    data: FormSchemaOtpRequestPage;
    navigate: NavigateFunction;
  }) => {
    try {
      const response = await callApiFn<CustomResponse<IAuth>>(() =>
        apiFetch({
          url: `${otpRequestUrl}`,
          options: {
            method: "POST",
            body: JSON.stringify(data),
          },
        }),
      );

      if (response.success) {
        localStorage.setItem("access_token", response.data.access_token);

        if (response.data?.two_fa_required === true) {
          navigate(routes.twoFA);
        }
        if (response.data?.two_fa_required === false) {
          /** Задача https://prodamus.kaiten.ru/space/138816/card/41460753.
           * новый флоу авторизации через страницу approve/deny приложений, redirect_to в этом кейсе больше не используем
           * удалить закоменченный код, после обкатки нового флоу
           */
          // if (response.data?.redirect_to) {
          //   let redirectTo = response.data.redirect_to;

          //   const hrefRedirectWithReturnUrl = `${redirectTo}&returnUrl=${window.location.origin}${routes.profile}`;
          //   if (
          //     validateUrlProtocol(hrefRedirectWithReturnUrl) &&
          //     validateUrlOrigin()
          //   ) {
          //     window.location.href = hrefRedirectWithReturnUrl;
          //   }
          // } else {
          //   navigate(routes.profile);
          // }

          navigate(routes.profile);
        }

        return response.data;
      } else {
        throw response;
      }
    } catch (e: FetchError | any) {
      store.dispatch(errorSlice.actions.setCustomError(e.errors));
    }
  },
);
