import { showNotification } from "@/services/notification";

import { UserState } from "../type";
import { PayloadAction } from "@reduxjs/toolkit";

export const getConfirmCodeEmailThunkReducer = {
  pending: (state: UserState) => {
    state.editing = true;
  },
  fulfilled: (state: UserState, action: PayloadAction<string | undefined>) => {
    state.editing = false;

    if (action.payload) {
      state.otpEmail = action.payload;
    }
  },
  rejected: (state: UserState) => {
    state.editing = false;

    showNotification("GlobalError");
  },
};
