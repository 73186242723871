import { NavigateFunction } from "react-router";

import { routes } from "@/pages/routes";
import { errorSlice } from "@/store/error";
import { store } from "@/store/configureStore";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IResponseRegistration } from "@/store/auth";
import { registerationUrl } from "@/endpoints/apiUrl";
import { apiFetch, callApiFn, FetchError } from "@/services/apiFetch";
import { FormSchemaRegistrationPage } from "@/constants/validateSchema";

export const registrationThunk = createAsyncThunk(
  "auth/registration",
  async ({
    data,
    navigate,
  }: {
    data: FormSchemaRegistrationPage;
    navigate?: NavigateFunction;
  }) => {
    try {
      const response = await callApiFn<IResponseRegistration>(() =>
        apiFetch({
          url: `${registerationUrl}`,
          options: {
            method: "POST",
            body: JSON.stringify(data),
          },
        }),
      );

      if (response.success) {
        localStorage.setItem("access_token", response.access_token);

        if (navigate && response?.two_fa_required === true) {
          navigate(routes.twoFA);
        }
        if (navigate && response?.two_fa_required === false) {
          navigate(routes.profile);
        }
        return response;
      } else {
        throw response;
      }
    } catch (e: FetchError | any) {
      store.dispatch(errorSlice.actions.setCustomError(e.errors));
    }
  },
);
