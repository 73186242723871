import { errorSlice } from "@/store/error";
import { store } from "@/store/configureStore";
import { ITwoFARequestBody, ITwoFAResponseBody } from "@/store/auth";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { twoFARequestUrl } from "@/endpoints/apiUrl";
import { CustomResponse } from "@/services/apiFetch.type";
import { apiFetch, callApiFn, FetchError } from "@/services/apiFetch";

export const twoFAThunk = createAsyncThunk(
  "auth/twoFA",
  async (data: ITwoFARequestBody) => {
    try {
      const response = await callApiFn<CustomResponse<ITwoFAResponseBody>>(() =>
        apiFetch({
          url: `${twoFARequestUrl}`,
          options: {
            method: "POST",
            headers: {
              Authorization: "Bearer " + data.two_fa_token,
            },
          },
        }),
      );

      if (response.success) {
        return response.data.email;
      } else {
        throw response;
      }
    } catch (e: FetchError | any) {
      store.dispatch(errorSlice.actions.setCustomError(e.errors));
    }
  },
);
