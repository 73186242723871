import { Middleware } from "@reduxjs/toolkit";
import { ApplicationStore } from "@/store/configureStore";
import { showNotification } from "@/services/notification";
import { logoutThunk } from "@/store/auth";

export const xcrfTokenMiddleware: Middleware<
  Middleware<{}, ApplicationStore>
> =
  ({ dispatch }) =>
  next =>
  action => {
    if (action.payload && action.payload.code === "auth_csrf_token_expired") {
      showNotification("AuthCsrfTokenExpired");
      return;
    }
    if (action.payload && action.payload.code === "auth_csrf_token_invalid") {
      showNotification("AuthCsrfTokenInvalid");
      localStorage.removeItem("access_token");
      localStorage.removeItem("trust_token");
      window.location.href = "/";
      window.location.reload();
      return;
    }
    return next(action);
  };
