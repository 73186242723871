import style from "./Title.module.css";
import cn from "classnames";

export interface TitleProps {
  className?: string;
  title?: string;
}
export const Title = ({ className, title }: TitleProps) => {
  return <h3 className={cn(style.title, className)}>{title}</h3>;
};
