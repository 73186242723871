import style from "./Toast.module.css";

import { ToastContainer, TypeOptions } from "react-toastify";
import OkayLogo from "@/assets/icons/okay.svg?react";
import WarnLogo from "@/assets/icons/warn.svg?react";

const customIcons: Record<TypeOptions, React.FC<{ className?: string }>> = {
  success: OkayLogo,
  info: WarnLogo,
  error: WarnLogo,
  warning: WarnLogo,
  default: WarnLogo,
};

export const Toast = () => {
  return (
    <ToastContainer
      toastClassName={style.toast}
      closeButton={false}
      hideProgressBar={true}
      icon={({ type }) => {
        const Icon = customIcons[type];
        return <Icon className={style.icon} />;
      }}
    />
  );
};
