import { Errors } from "./messages";

import { z } from "zod";
import { Regexp } from "./regexp";

// TODO: надо пофиксить это дело. не очевидный моммент сюда добавления новых схем
export type FormSchemaCommonFields =
  | FormSchemaLoginPage
  | FormSchemaRegistrationPage
  | FormSchemaConfirmForgotPasswordPage
  | FormSchemaForgotPasswordPage
  | FormSchemaOtpRequestPage
  | FormSchemaTwoFAPage
  | FormSchemaConfirmEmailByCodeModal
  | FormSchemaConfirmPhoneByCodeModal
  | FormSchemaEditPhoneModal
  | FormSchemaEditEmailModal
  | FormSchemaMasqueradePage
  | FormSchemaEditPasswordModal;

export type FormSchemaLoginPage = z.infer<typeof formSchemaLoginPage>;

export const formSchemaLoginPage = z.object({
  email: z.string().min(1, Errors.required).regex(Regexp.email, {
    message: Errors.invalidEmail,
  }),
  password: z.string().min(8, Errors.invalidPasswordLength),
  remember_me: z.boolean(),
  trust_token: z.union([z.string(), z.null()]).optional(),
});

export type FormSchemaRegistrationPage = z.infer<
  typeof formSchemaRegistrationPage
>;

export const formSchemaRegistrationPage = z
  .object({
    name: z.string().min(1, Errors.required),
    email: z.string().min(1, Errors.required).regex(Regexp.email, {
      message: Errors.invalidEmail,
    }),
    phone: z.string().min(1, Errors.required),
    password: z.string().min(8, Errors.invalidPasswordLengthAndConfirm),
    password_confirmation: z
      .string()
      .min(8, Errors.invalidPasswordLengthAndConfirm),
    is_agree_with_rules: z.literal<boolean>(true, {
      errorMap: () => ({ message: Errors.required }),
    }),
    captcha_token: z.string(),
  })
  .refine(data => data.password_confirmation === data.password, {
    message: "Пароли должны совпадать",
    path: ["password_confirmation"],
  });

export type FormSchemaConfirmForgotPasswordPage = z.infer<
  typeof formSchemaConfirmForgotPasswordPage
>;

export const formSchemaConfirmForgotPasswordPage = z
  .object({
    email: z.string(),
    token: z.string(),
    password: z.string().min(8, Errors.invalidPasswordLengthAndConfirm),
    password_confirmation: z
      .string()
      .min(8, Errors.invalidPasswordLengthAndConfirm),
  })
  .refine(data => data.password_confirmation === data.password, {
    message: "Пароли должны совпадать",
    path: ["password_confirmation"],
  });

export type FormSchemaForgotPasswordPage = z.infer<
  typeof formSchemaForgotPasswordPage
>;

export const formSchemaForgotPasswordPage = z.object({
  email: z.string().min(1, Errors.required).regex(Regexp.email, {
    message: Errors.invalidEmail,
  }),
  recovery_url: z.string(),
});

export type FormSchemaOtpRequestPage = z.infer<typeof formSchemaOtpRequestPage>;

export const formSchemaOtpRequestPage = z.object({
  email: z.string().min(1, Errors.required).regex(Regexp.email, {
    message: Errors.invalidEmail,
  }),
  remember_me: z.boolean(),
});

export type FormSchemaTwoFAPage = z.infer<typeof formSchemaTwoFAPage>;

export const formSchemaTwoFAPage = z.object({
  code: z.string().min(6, Errors.invalidCodeLength),
  two_fa_token: z.string(),
});

export type FormSchemaConfirmEmailByCodeModal = z.infer<
  typeof formSchemaConfirmEmailByCodeModal
>;

export const formSchemaConfirmEmailByCodeModal = z.object({
  code: z.string().min(6, Errors.invalidCodeLength),
});

export type FormSchemaConfirmPhoneByCodeModal = z.infer<
  typeof formSchemaConfirmPhoneByCodeModal
>;

export const formSchemaConfirmPhoneByCodeModal = z.object({
  code: z.string().min(6, Errors.invalidCodeLength),
});

export type FormSchemaEditPhoneModal = z.infer<typeof formSchemaEditPhoneModal>;

export const formSchemaEditPhoneModal = z.object({
  phone: z.string(),
});

export type FormSchemaEditEmailModal = z.infer<typeof formSchemaEditEmailModal>;

export const formSchemaEditEmailModal = z.object({
  email: z.string().min(1, Errors.required).regex(Regexp.email, {
    message: Errors.invalidEmail,
  }),
});

export type FormSchemaEditPasswordModal = z.infer<
  typeof formSchemaEditPasswordModal
>;

export const formSchemaEditPasswordModal = z.object({
  password_old: z.string().min(8, Errors.invalidPasswordLength),
  password: z.string().min(8, Errors.invalidPasswordLengthAndConfirm),
  password_confirmation: z
    .string()
    .min(8, Errors.invalidPasswordLengthAndConfirm),
});

export type FormSchemaMasqueradePage = z.infer<typeof formSchemaMasqueradePage>;

export const formSchemaMasqueradePage = z.object({
  user_id: z.string().min(1, Errors.required),
});
