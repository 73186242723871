import { useEffect } from "react";

import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { errorSlice } from "@/store/error";
import { ErrorMessage } from "@/components";
import { Button, FormFieldInput } from "@/ui";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAppDispatch } from "@/hooks/useAppDispatch.hook";
import { useFormErrorFromBackend } from "@/hooks/useFormErrorFromBackend";
import { editPasswordThunk, getUserState } from "@/store/user";
import {
  formSchemaEditPasswordModal,
  FormSchemaEditPasswordModal,
} from "@/constants/validateSchema";

import style from "./EditModal.module.css";
import { Container, EditModalProps } from "./Container";

const defaultValuesForm: FormSchemaEditPasswordModal = {
  password_old: "",
  password: "",
  password_confirmation: "",
};

export const EditPassword = ({ onClose }: EditModalProps) => {
  const dispatch = useAppDispatch();
  const user = useSelector(getUserState);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting, isSubmitted, isDirty },
    setError,
  } = useForm<FormSchemaEditPasswordModal>({
    resolver: zodResolver(formSchemaEditPasswordModal),
    defaultValues: defaultValuesForm,
  });

  useFormErrorFromBackend<FormSchemaEditPasswordModal>({
    setError,
    fieldNames: ["password_old", "password", "password_confirmation"],
  });

  const onSubmitHandler = (val: FormSchemaEditPasswordModal) => {
    dispatch(editPasswordThunk(val));
  };

  useEffect(() => {
    if (user.isPasswordChanged) {
      onClose();
    }
  }, [user.isPasswordChanged]);

  return (
    <Container
      title={"Изменение пароля"}
      onClose={onClose}
      hint={`Придумайте новый пароль для своего профиля. Мы рекомендуем
          использовать в пароле хотя бы один спецсимвол и одну заглавную букву.
          Допускается использование цифр и букв латинского алфавита.`}
    >
      <form onSubmit={handleSubmit(onSubmitHandler)} className={style.form}>
        <FormFieldInput<FormSchemaEditPasswordModal>
          type="password"
          name="password_old"
          control={control}
          register={register}
          error={errors.password_old}
          title={"Текущий пароль"}
          placeholder={"Введите пароль"}
          isRequiredField
          autoFocus
        />
        <FormFieldInput<FormSchemaEditPasswordModal>
          type="password"
          name="password"
          control={control}
          register={register}
          error={errors.password}
          title={"Новый пароль"}
          placeholder={"Введите пароль"}
          isRequiredField
          autoComplete={"password"}
        />
        <FormFieldInput<FormSchemaEditPasswordModal>
          type="password"
          name="password_confirmation"
          control={control}
          register={register}
          error={errors.password_confirmation}
          title={"Повторите новый пароль"}
          placeholder={"Введите пароль"}
          isRequiredField
          autoComplete={"password"}
        />

        <ErrorMessage />

        <div className={style.actions}>
          <Button
            className={style.btn}
            name={"Отмена"}
            type={"button"}
            onClick={onClose}
            secondary
          />
          <Button
            className={style.submit}
            name={"Подтвердить"}
            disabled={
              isSubmitting ||
              !isDirty ||
              (isSubmitted && !isValid && !isDirty) ||
              user.editing
            }
            type={"submit"}
            isLoading={user.editing}
            onClick={() => {
              dispatch(errorSlice.actions.resetError());
            }}
          />
        </div>
      </form>
    </Container>
  );
};
