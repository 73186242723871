import { CSSProperties } from "react";

import { FieldError } from "react-hook-form";

import { TitleField, TitleFieldProps } from "@/ui";
import { FormSchemaCommonFields } from "@/constants/validateSchema";

import style from "./FormField.module.css";
import { CheckboxField, CheckboxFieldProps, ErrorField } from "./components";

interface FormFieldProps<T extends FormSchemaCommonFields>
  extends CheckboxFieldProps<T> {
  /** Текст над полем ввода */
  title?: string;
  /** Текст для тултипа (всплывающей подсказки) */
  tooltipTitle?: string;
  /** Флаг, true если необходимо отображать двоеточие после названия */
  isColon?: boolean;
  /** Стили для контейнера компонента TitleField */
  styleContainerTitleField?: CSSProperties;
  /** Стили для контейнера компонента FormField */
  styleContainer?: CSSProperties;
  /** Обьект ошибки поля */
  error?: FieldError;
}

export function FormFieldCheckbox<T extends FormSchemaCommonFields>({
  ...props
}: FormFieldProps<T>) {
  const titleFieldProps: TitleFieldProps = {
    title: props.title,
    tooltipTitle: props.tooltipTitle,
    isColon: props.isColon,
    styleContainer: props.styleContainerTitleField,
    isRequiredField: props.isRequiredField,
  };

  const checkboxFieldProps: CheckboxFieldProps<T> = {
    name: props.name,
    control: props.control,
    titleCheckbox: props.titleCheckbox,
    defaultValue: props.defaultValue,
    isRequiredField: props.isRequiredField,
    classNameContainerCheckbox: props.classNameContainerCheckbox,
    children: props.children,
  };

  return (
    <div className={style.containerField} style={props.styleContainer}>
      <TitleField {...titleFieldProps} />

      <CheckboxField<T> {...checkboxFieldProps} />

      <ErrorField error={props.error} />
    </div>
  );
}
