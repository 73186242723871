import { FieldError } from "react-hook-form";

import style from "./ErrorField.module.css";

interface ErrorFieldProps {
  error?: FieldError;
}
export const ErrorField = ({ error }: ErrorFieldProps) => {
  return (
    error && (
      <div className={style.container}>
        <span className={style.text}>{error.message}</span>
      </div>
    )
  );
};
