import { CSSProperties } from "react";

import { TitleField, TitleFieldProps } from "@/ui";

import style from "./FormField.module.css";
import { ErrorField, InputField, InputFieldProps } from "./components";
import { FormSchemaCommonFields } from "@/constants/validateSchema";

interface FormFieldProps<T extends FormSchemaCommonFields>
  extends InputFieldProps<T> {
  /** Текст над полем ввода */
  title?: string;
  /** Флаг, true если поле обязательное */
  isRequiredField?: boolean;
  /** Текст для тултипа (всплывающей подсказки) */
  tooltipTitle?: string;
  /** Флаг, true если необходимо отображать двоеточие после названия */
  isColon?: boolean;
  /** Стили для контейнера компонента TitleField */
  styleContainerTitleField?: CSSProperties;
  /** Стили для контейнера компонента FormField */
  styleContainer?: CSSProperties;
}

export function FormFieldInput<T extends FormSchemaCommonFields>(
  props: FormFieldProps<T>,
) {
  const titleFieldProps: TitleFieldProps = {
    title: props.title,
    tooltipTitle: props.tooltipTitle,
    isColon: props.isColon,
    styleContainer: props.styleContainerTitleField,
    isRequiredField: props.isRequiredField,
  };

  const inputFieldProps: InputFieldProps<T> = {
    ...props,
    type: props.type,
    placeholder: props.placeholder,
    register: props.register,
    valueAsNumber: props.valueAsNumber,
    name: props.name,
  };

  return (
    <div className={style.containerField} style={props.styleContainer}>
      <TitleField {...titleFieldProps} />

      <InputField<T> {...inputFieldProps} />

      <ErrorField error={props.error} />
    </div>
  );
}
