import { allowedOrigins } from "@/pages/routes";
import { showNotification } from "@/services/notification";

export function validateUrlOrigin(): boolean {
  if (allowedOrigins.includes(window.location.origin)) {
    return true;
  }
  showNotification("InvalidOriginUrl");
  return false;
}
