import { isDev } from "@/constants/common";
import { showNotification } from "@/services/notification";

export function validateUrlProtocol(url: string): string | false {
  if (isDev) {
    if (url.startsWith("https://") || url.startsWith("http://")) {
      return url;
    }
  } else {
    if (url.startsWith("https://")) {
      return url;
    }
  }

  showNotification("InvalidRedirectUrl");
  return false;
}
