import { NavigateFunction } from "react-router";

import { routes } from "@/pages/routes";
import { errorSlice } from "@/store/error";
import { store } from "@/store/configureStore";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { resetRequestUrl } from "@/endpoints/apiUrl";
import { CustomResponse } from "@/services/apiFetch.type";
import { apiFetch, callApiFn, FetchError } from "@/services/apiFetch";
import { FormSchemaForgotPasswordPage } from "@/constants/validateSchema";

export const resetPasswordRequestThunk = createAsyncThunk(
  "auth/resetPasswordRequest",
  async ({
    data,
    navigate,
  }: {
    data: FormSchemaForgotPasswordPage;
    navigate: NavigateFunction;
  }) => {
    try {
      const response = await callApiFn<CustomResponse<undefined>>(() =>
        apiFetch({
          url: `${resetRequestUrl}`,
          options: {
            method: "POST",
            body: JSON.stringify(data),
          },
        }),
      );

      if (response.success) {
        navigate(routes.resetLinkSent, { state: { email: data.email } });
      } else {
        throw response;
      }
    } catch (e: FetchError | any) {
      store.dispatch(errorSlice.actions.setCustomError(e.errors));
    }
  },
);
