import { ReactNode } from "react";

import cn from "classnames";

import style from "./Page.module.css";
import { Loader } from "@/ui";

export interface PageProps {
  className?: string;
  children?: ReactNode | ReactNode[];
  isLoading?: boolean;
}
export const Page = ({ className, children, isLoading = false }: PageProps) => {
  if (isLoading) {
    return <Loader.Main className={cn(style.page, style.isLoadingPage)} />;
  }
  return <div className={cn(style.page, className)}>{children}</div>;
};
