import { IUser } from "@/store/user";
import { errorSlice } from "@/store/error";
import { store } from "@/store/configureStore";
import { getUserUrl } from "@/endpoints/apiUrl";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { CustomResponse } from "@/services/apiFetch.type";
import { apiFetch, callApiFn, FetchError } from "@/services/apiFetch";

export const getUserThunk = createAsyncThunk("user/getUser", async () => {
  try {
    const response = await callApiFn<CustomResponse<IUser>>(() =>
      apiFetch({
        url: `${getUserUrl}`,
        options: {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        },
      }),
    );

    if (response.success) {
      return response.data;
    } else {
      throw response;
    }
  } catch (e: FetchError | any) {
    store.dispatch(errorSlice.actions.setCustomError(e.errors));
  }
});
