import { useEffect, useRef, useState } from "react";

import { showNotification } from "@/services/notification";

import { WidgetId } from "./useCaptcha.type";

const KEY_YCAPTCHA = import.meta.env.VITE_KEY_YCAPTCHA;
const URL_YCAPTCHA_SCRIPT =
  "https://smartcaptcha.yandexcloud.net/captcha.js?render=onload&onload=onloadCaptcha";

export const useCaptcha = (callbackSuccess: (token: string) => void) => {
  let refCaptchaId = useRef<WidgetId>();
  const [isLoadingCaptchaScript, setIsLoadingCaptchaScript] = useState(false);
  const [isCaptchaReady, setIsCaptchaReady] = useState(false);
  const [isCaptchaError, setIsCaptchaError] = useState(false);

  useEffect(() => {
    setIsLoadingCaptchaScript(true);

    window.onloadCaptcha = () => {
      if (window?.smartCaptcha) {
        refCaptchaId.current = window.smartCaptcha.render("captcha-container", {
          sitekey: KEY_YCAPTCHA,
          invisible: true,
          callback: callbackSuccess,
        });
        setIsCaptchaReady(true);
        setIsLoadingCaptchaScript(false);
      }
    };

    const scriptElement = document.createElement("script");
    scriptElement.src = URL_YCAPTCHA_SCRIPT;
    scriptElement.async = true;

    scriptElement.onload = () => {
      console.log("Captcha script loaded successfully");
      setIsLoadingCaptchaScript(false);
    };
    scriptElement.onerror = () => {
      showNotification("GlobalCaptchaError");
      console.log("Failed to load the captcha script");
      setIsLoadingCaptchaScript(false);
      setIsCaptchaError(true);
    };

    document.body.appendChild(scriptElement);

    return () => {
      document.body.removeChild(scriptElement);
    };
  }, []);

  return {
    refCaptchaId,
    isLoadingCaptchaScript,
    isCaptchaError,
    isCaptchaReady,
  };
};
