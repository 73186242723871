export enum ModalTypes {
  ConfirmEmail = "confirmEmail",
  ConfirmPhone = "confirmPhone",
  EditEmail = "editEmail",
  EditPhone = "editPhone",
  EditPassword = "editPassword",
}

export type ModalTypeState = ModalTypes | null;

export interface ModalState {
  modalType: ModalTypeState;
}
