import { RefObject } from "react";
import { Tooltip } from "antd";

import style from "../TitleField.module.css";

import imgQuestionActive from "../assets/question-active.png";
import imgQuestionDefault from "../assets/question-default.png";

type Props = {
  tooltipTitle: string;
  hoverRef: RefObject<HTMLImageElement>;
  isHover: boolean;
};

export const CustomTooltip = ({ tooltipTitle, hoverRef, isHover }: Props) => {
  if (!tooltipTitle) return;

  return (
    <Tooltip
      placement="top"
      title={tooltipTitle}
      arrow={false}
      overlayInnerStyle={{
        backgroundColor: "#8C8E94",
        padding: 30,
        fontSize: 14,
        fontFamily: "Roboto",
        lineHeight: "24px",
      }}
    >
      <img
        ref={hoverRef}
        className={style.question}
        src={isHover ? imgQuestionActive : imgQuestionDefault}
      />
    </Tooltip>
  );
};
