import {
  CustomResponse,
  EErrorCode,
  FieldError,
} from "@/services/apiFetch.type";

interface ApiFetchParams {
  url: string;
  options?: RequestInit;
  baseUrl?: boolean;
}

type Func<T> = () => Promise<T>;

export class FetchError extends Error {
  public code: EErrorCode;
  public message: string;
  public fields: FieldError;

  constructor(
    readonly status: number,
    readonly errors: ErrorObject,
  ) {
    super();
    this.code = errors.code;
    this.message = errors.message;
    this.fields = errors.fields;
  }
}

export interface ErrorObject {
  code: EErrorCode;
  message: string;
  fields: FieldError;
}

export const getBaseUrl = (): string => {
  return import.meta.env.VITE_API_BASE_URL as string;
};

function handleResponse<R>(response: Response): Promise<R> {
  return Promise.resolve<R>(response.json());
}

let XCsrfToken: string | null;

export const getXCsrfToken = (response: Response): string | null => {
  const newToken = response.headers.get("X-Csrf-Token");

  return newToken ? newToken : XCsrfToken;
};

function validationXCsrfToken<T>(response: Awaited<T>): 1 | 0 {
  if (
    response &&
    typeof response === "object" &&
    "errors" in response &&
    response.errors &&
    typeof response.errors === "object" &&
    "code" in response.errors
  ) {
    if (
      response?.errors?.code === "auth_csrf_token_expired" ||
      response?.errors?.code === "auth_csrf_token_invalid"
    ) {
      return 0;
    }
  }

  return 1;
}

export async function apiFetch<T>({
  url,
  baseUrl = true,
  options = {},
}: ApiFetchParams): Promise<T> {
  const token = localStorage
    .getItem("access_token")
    ?.replace(/"([^"]+(?="))"/g, "$1");

  const isContentType = new Headers(options?.headers).get("Content-Type");
  const headers: HeadersInit = new Headers({
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token ?? ""}`,
    ...options?.headers,
  });
  if (isContentType === "multipart/form-data") {
    headers.delete("Content-Type");
  }
  if (XCsrfToken) {
    headers.append("X-Csrf-Token", XCsrfToken);
  }
  let response: Response;
  if (baseUrl) {
    response = await fetch(`${getBaseUrl()}/${url}`, {
      ...options,
      headers: headers,
    });
  } else {
    response = await fetch(url, {
      ...options,
      headers: headers,
    });
  }

  const contentType: string | null = response.headers.get("content-type");
  XCsrfToken = getXCsrfToken(response);

  if (!contentType?.includes("application/json")) {
    throw new Error("Запрос поддерживает только данные в формате json");
  }
  if (!response.ok) {
    const resp: CustomResponse<any> = await response.json();

    throw new FetchError(response.status, resp.errors);
  }
  return handleResponse<T>(response);
}

export async function callApiFn<T>(func: Func<T>): Promise<T> {
  try {
    const responseApiFn = await func();

    return responseApiFn;
  } catch (e) {
    // @ts-ignore
    if (e instanceof FetchError) {
      console.error("Возникла ошибка во время запроса", e);
    }
    throw e;
  }
}
