import { ReactNode } from "react";

import cn from "classnames";

import { Button, TitleField } from "@/ui";
import { formatPhoneNumber } from "@/utils/formatPhoneNumber";
import PasswordDotLogo from "@/assets/icons/password-dot.svg?react";
import OkayLogo from "@/assets/icons/okay.svg?react";
import WarnLogo from "@/assets/icons/warn.svg?react";

import style from "./ProfileField.module.css";

type TType = "text" | "password" | "phone";

type TConfirm = {
  isConfirm: boolean;
  successText: string;
  failedText: string;
  handleConfirm: () => void;
};

interface ProfileFieldPops {
  title: string;
  value?: string | ReactNode;
  onEdit?: () => void;
  confirm?: TConfirm;
  type?: TType;
}

export const ProfileField = ({
  title,
  value,
  onEdit,
  confirm = undefined,
  type = "text",
}: ProfileFieldPops) => {
  return (
    <div className={style.containerField}>
      {title && <TitleField title={title} isRequiredField={true} />}
      <div className={style.field}>
        {type === "password" && (
          <div className={cn(style.password, style.val)}>
            <PasswordDotLogo />
            <PasswordDotLogo />
            <PasswordDotLogo />
            <PasswordDotLogo />
            <PasswordDotLogo />
            <PasswordDotLogo />
            <PasswordDotLogo />
          </div>
        )}
        {type === "text" && (
          <div className={style.val}>{value ?? "Нет данных"}</div>
        )}

        {type === "phone" && (
          <div className={style.val}>
            {formatPhoneNumber(value) ?? "Нет данных"}
          </div>
        )}

        {onEdit && (
          <Button
            className={style.btn}
            name={"Изменить"}
            type={"button"}
            onClick={onEdit}
            ghost
          />
        )}
      </div>

      {confirm !== undefined && (
        <div
          className={cn(style.confirm, {
            [style.confirmSuccess]: confirm.isConfirm,
          })}
        >
          {confirm.isConfirm ? <OkayLogo /> : <WarnLogo />}
          <div className={style.confirm_wrapper}>
            <span className={style.text}>
              {confirm.isConfirm ? confirm.successText : confirm.failedText}
            </span>
            {!confirm.isConfirm && (
              <span
                className={style.action}
                onClick={() => {
                  confirm.handleConfirm();
                }}
              >
                Подтвердить
              </span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
