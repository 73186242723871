import { NavigateFunction } from "react-router";
import { routes } from "@/pages/routes";
import { errorSlice } from "@/store/error";
import { store } from "@/store/configureStore";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { CustomResponse } from "@/services/apiFetch.type";
import { validateUrlProtocol } from "@/utils/validateUrlProtocol";
import { apiFetch, callApiFn, FetchError } from "@/services/apiFetch";
import {
  oauth2AuthorizeApprove,
  oauth2AuthorizeDeny,
  oauth2AuthorizePrepare,
} from "@/endpoints/apiUrl";
import { IOAuthPrepareData, OAuthAuthorizePrepareReturn } from "../type";

export const oauthAuthorizePrepareThunk = createAsyncThunk(
  "auth/oauthAuthorizePrepare",
  async ({ queryParams }: { queryParams: string }) => {
    try {
      const response = await callApiFn<
        CustomResponse<OAuthAuthorizePrepareReturn>
      >(() =>
        apiFetch({
          url: `${oauth2AuthorizePrepare}?${queryParams}`,
          options: {
            method: "GET",
          },
        }),
      );

      if (response.success) {
        const data = response.data;
        if (data && "redirect_to" in data && data?.redirect_to) {
          if (validateUrlProtocol(data?.redirect_to)) {
            window.location.href = data?.redirect_to;
            return {
              isPendingOauthRedirect: true,
            };
          }
        } else {
          return data;
        }
      } else {
        throw response;
      }
    } catch (e: FetchError | any) {
      store.dispatch(errorSlice.actions.setCustomError(e.errors));
    }
  },
);

export const oauthAuthorizeDenyThunk = createAsyncThunk(
  "auth/oauthAuthorizeDeny",
  async ({
    data,
    navigate,
  }: {
    data: IOAuthPrepareData;
    navigate: NavigateFunction;
  }) => {
    try {
      await callApiFn<CustomResponse<any>>(() =>
        apiFetch({
          url: `${oauth2AuthorizeDeny}`,
          options: {
            method: "POST",
            body: JSON.stringify(data),
          },
        }),
      );
      navigate(routes.profile);
    } catch (e: FetchError | any) {
      store.dispatch(errorSlice.actions.setCustomError(e.errors));
    }
  },
);

export const oauthAuthorizeApproveThunk = createAsyncThunk(
  "auth/oauthAuthorizeApprove",
  async ({ data }: { data: IOAuthPrepareData }) => {
    try {
      const response = await callApiFn<CustomResponse<any>>(() =>
        apiFetch({
          url: `${oauth2AuthorizeApprove}`,
          options: {
            method: "POST",
            body: JSON.stringify(data),
          },
        }),
      );

      if (response.success) {
        const data = response.data;
        if (data?.redirect_to) {
          if (validateUrlProtocol(data?.redirect_to)) {
            window.location.href = data?.redirect_to;
          }
        }
      } else {
        throw response;
      }
    } catch (e: FetchError | any) {
      store.dispatch(errorSlice.actions.setCustomError(e.errors));
    }
  },
);
