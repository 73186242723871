export function formatPhoneNumber(phoneNumber?: any) {
  if (!phoneNumber || !phoneNumber.startsWith("+7")) {
    return phoneNumber;
  }

  const digits = phoneNumber.replace(/\D/g, "");

  if (digits.startsWith("7") && digits.length === 11) {
    const match = digits.match(/^(\d)(\d{3})(\d{3})(\d{2})(\d{2})$/);
    if (match) {
      return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}-${match[5]}`;
    }
  }

  return phoneNumber;
}
