import { errorSlice } from "@/store/error";
import { IApplication } from "@/store/user";
import { store } from "@/store/configureStore";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { applicationsUrl } from "@/endpoints/apiUrl";
import { CustomResponse } from "@/services/apiFetch.type";
import { apiFetch, callApiFn, FetchError } from "@/services/apiFetch";

export const getApplicationsThunk = createAsyncThunk(
  "user/getApplications",
  async () => {
    try {
      const response = await callApiFn<CustomResponse<IApplication[]>>(() =>
        apiFetch({
          url: `${applicationsUrl}`,
          options: {
            method: "GET",
          },
        }),
      );

      if (response.success) {
        return response.data;
      } else {
        throw response;
      }
    } catch (e: FetchError | any) {
      store.dispatch(errorSlice.actions.setCustomError(e.errors));
    }
  },
);
