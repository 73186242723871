import { ToastOptions, toast } from "react-toastify";
import {
  NotificationKeys,
  NotificationValue,
  NotificationConfig,
} from "./config";

export const showNotification = (
  key: NotificationKeys | "Custom",
  customConfig?: NotificationValue & ToastOptions,
  config?: Omit<NotificationValue, "type" | "content">,
) => {
  let mergedConfig: NotificationValue = {
    type: "warning",
    content: "Стандратное уведомление.",
  };

  if (key !== "Custom") {
    mergedConfig = {
      ...NotificationConfig[key],
      ...config,
    };
  }

  if (key === "Custom" && customConfig) {
    mergedConfig = { ...customConfig };
  }

  const { type, content, ...mrgdConfig } = mergedConfig;

  const textContent = content.split("<br />").map((line, index) => (
    <span key={index}>
      {line}
      <br />
    </span>
  ));

  toast[type](<>{textContent}</>, {
    position: toast.POSITION.TOP_CENTER,
    ...mrgdConfig,
  });
};
