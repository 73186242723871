import { PayloadAction } from "@reduxjs/toolkit";

import { AuthState, IAuth } from "../type";

export const otpRequestThunkReducer = {
  pending: (state: AuthState) => {
    state.loading = true;
  },
  fulfilled: (state: AuthState, action: PayloadAction<IAuth | undefined>) => {
    state.loading = false;

    if (action.payload) {
      // action.payload может быть только true - означает, что код был отправлен
      state.data = action.payload;

      if (action.payload?.access_token) {
        localStorage.setItem("access_token", action.payload.access_token);
      }
      if (action.payload?.trust_token) {
        localStorage.setItem("trust_token", action.payload.trust_token);
      }
    }
  },
  rejected: (state: AuthState) => {
    state.loading = false;
  },
};
