import ReactDOM from "react-dom/client";
import {
  ApplicationStore,
  createApplicationStore,
} from "@/store/configureStore";
import { Store } from "redux";
import App from "@/App";
import { Provider } from "react-redux";
import { SentryContainer, AuthContainer } from "./containers";
import { BrowserRouter } from "react-router-dom";
import { ModalContainer } from "./pages/profile/containers/modal";

const store: Store<ApplicationStore> = createApplicationStore();

ReactDOM.createRoot(document.getElementById("root")!).render(
  <SentryContainer>
    <Provider store={store}>
      <BrowserRouter>
        <ModalContainer>
          <AuthContainer>
            <App />
          </AuthContainer>
        </ModalContainer>
      </BrowserRouter>
    </Provider>
  </SentryContainer>,
);
