import { useEffect, useMemo, useState } from "react";

import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

import { routes } from "@/pages/routes";
import { useCaptcha } from "@/hooks/useCaptcha";
import { zodResolver } from "@hookform/resolvers/zod";
import { errorSlice, getErrorState } from "@/store/error";
import { showNotification } from "@/services/notification";
import { useAppDispatch } from "@/hooks/useAppDispatch.hook";
import { Button, FormFieldCheckbox, FormFieldInput } from "@/ui";
import { ErrorMessage, Page, StyledForm, Title } from "@/components";
import { addPlusPhoneFormField } from "@/utils/addPlusPhoneFormField";
import { authSlice, getAuthState, registrationThunk } from "@/store/auth";
import { useFormErrorFromBackend } from "@/hooks/useFormErrorFromBackend";
import {
  formSchemaRegistrationPage,
  FormSchemaRegistrationPage,
} from "@/constants/validateSchema";

import style from "./RegistrationPage.module.css";
import { CheckboxTitleIsAgreeWithRules } from "./components";

const defaultValuesForm: FormSchemaRegistrationPage = {
  name: "",
  email: "",
  phone: "",
  password: "",
  password_confirmation: "",
  is_agree_with_rules: false,
  captcha_token: "",
};

export const RegistrationPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const auth = useSelector(getAuthState);
  const error = useSelector(getErrorState);
  const [formValues, setFormValues] =
    useState<FormSchemaRegistrationPage>(defaultValuesForm);
  const [isPendingRenderCaptcha, setIsPendingRenderCaptcha] = useState(false);

  const isErrorCaptchaTokenIsInvalid = useMemo(
    () =>
      error.customError?.fields?.captcha_token?.length &&
      error.customError?.code === "captcha_token_is_invalid",
    [error],
  );

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting, isSubmitted, isDirty },
    setError,
  } = useForm<FormSchemaRegistrationPage>({
    resolver: zodResolver(formSchemaRegistrationPage),
    defaultValues: defaultValuesForm,
  });

  useFormErrorFromBackend<FormSchemaRegistrationPage>({
    setError,
    fieldNames: ["name", "email", "phone", "password", "password_confirmation"],
  });

  const callbackSuccessRegistration = (token: string) => {
    if (
      window?.smartCaptcha &&
      refCaptchaId &&
      typeof refCaptchaId?.current === "number"
    ) {
      dispatch(authSlice.actions.setCaptchaToken(token));
      setIsPendingRenderCaptcha(false);

      window.smartCaptcha.reset(refCaptchaId.current);
    } else {
      showNotification("GlobalCaptchaError");
      console.log(
        "СallbackSuccessRegistration вызвался, но капча не загрузилась",
        refCaptchaId,
      );
    }
  };

  const { refCaptchaId, isLoadingCaptchaScript, isCaptchaError } = useCaptcha(
    callbackSuccessRegistration,
  );

  useEffect(() => {
    if (auth.captchaToken) {
      const mergedData = { ...formValues, captcha_token: auth.captchaToken };

      dispatch(
        registrationThunk(
          addPlusPhoneFormField({ data: mergedData, navigate }),
        ),
      );
    }
  }, [auth.captchaToken]);

  useEffect(() => {
    // Если с бека пришла ошибка капчи "captcha_token_is_invalid"
    if (isErrorCaptchaTokenIsInvalid) {
      if (window?.smartCaptcha) {
        try {
          setIsPendingRenderCaptcha(true);
          window.smartCaptcha.execute();
        } catch (err) {
          console.log("Ошибка капчи");
          showNotification("GlobalError");
        }
      }
    }
  }, [isErrorCaptchaTokenIsInvalid]);

  const onSubmitHandler = (values: FormSchemaRegistrationPage) => {
    if (!window.smartCaptcha) {
      // Скрипт капчи еще загружается
      if (isLoadingCaptchaScript) {
        showNotification("СaptchaTokenPending");
      }
      // Произошла ошибка при загрузке скрипта капчи
      if (isCaptchaError) {
        showNotification("GlobalCaptchaError");
      }
      return;
    }

    setFormValues(values);
    setIsPendingRenderCaptcha(true);
    window.smartCaptcha.execute();
  };

  useEffect(() => {
    dispatch(errorSlice.actions.resetError());
  }, []);

  return (
    <Page>
      <Title title={"Регистрация"} />
      <StyledForm handleSubmit={handleSubmit(onSubmitHandler)}>
        <FormFieldInput<FormSchemaRegistrationPage>
          type="input"
          name="name"
          isRequiredField
          title={"ФИО"}
          control={control}
          register={register}
          error={errors.name}
          placeholder={"Фамилия Имя Отчество"}
          autoFocus
        />
        <FormFieldInput<FormSchemaRegistrationPage>
          type="input"
          name="email"
          isRequiredField
          title={"E-mail"}
          control={control}
          register={register}
          error={errors.email}
          placeholder={"Введите e-mail"}
        />
        <FormFieldInput<FormSchemaRegistrationPage>
          type="phone"
          name="phone"
          isRequiredField
          title={"Мобильный телефон"}
          control={control}
          register={register}
          error={errors.phone}
          placeholder={"Введите номер"}
        />
        <FormFieldInput<FormSchemaRegistrationPage>
          type="password"
          name="password"
          control={control}
          register={register}
          error={errors.password}
          title={"Пароль"}
          placeholder={"Введите пароль"}
          isRequiredField
        />
        <FormFieldInput<FormSchemaRegistrationPage>
          type="password"
          name="password_confirmation"
          control={control}
          register={register}
          error={errors.password_confirmation}
          title={"Повторите пароль"}
          placeholder={"Введите пароль"}
          isRequiredField
        />
        <div className={style.actions}>
          <div className={style.rulesContainer}>
            <FormFieldCheckbox<FormSchemaRegistrationPage>
              titleCheckbox={""}
              name="is_agree_with_rules"
              control={control}
              error={errors.is_agree_with_rules}
              isRequiredField
            >
              <CheckboxTitleIsAgreeWithRules />
            </FormFieldCheckbox>
          </div>
        </div>
        <ErrorMessage />
        <Button
          className={style.submit}
          name={"ЗАРЕГИСТРИРОВАТЬСЯ"}
          type={"submit"}
          disabled={
            isSubmitting ||
            !isDirty ||
            (isSubmitted && !isValid && !isDirty) ||
            auth.loading ||
            isCaptchaError ||
            isPendingRenderCaptcha
          }
          isLoading={auth.loading || isPendingRenderCaptcha}
          onClick={() => {
            dispatch(errorSlice.actions.resetError());
          }}
        />
        <div className={style.other}>
          <p>У меня уже есть аккаунт.</p>
          <Link to={routes.login} className={style.link}>
            Войти
          </Link>
        </div>
      </StyledForm>
    </Page>
  );
};
