import style from "./ApplicationsPage.module.css";

import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { routes } from "@/pages/routes";
import { Loader } from "@/ui";
import { LinkBackPage, Title, Page } from "@/components";
import { useAppDispatch } from "@/hooks/useAppDispatch.hook";
import { getApplicationsThunk, getUserState } from "@/store/user";
import { getErrorState, errorSlice } from "@/store/error";
import SettingLogo from "@/assets/icons/setting.svg?react";
import { validateUrlProtocol } from "@/utils/validateUrlProtocol";

export const ApplicationsPage = () => {
  const dispatch = useAppDispatch();
  const auth = useSelector(getUserState);
  const error = useSelector(getErrorState);

  useEffect(() => {
    dispatch(errorSlice.actions.resetError());
    dispatch(getApplicationsThunk());
  }, []);

  const renderLoader = () => {
    if (auth.loading) {
      return <Loader.Main className={style.containerLoader} />;
    }
  };

  const renderContent = () => {
    if (!auth.listApplications || auth.loading) {
      return null;
    }

    return (
      <>
        {auth.listApplications?.map((item, index) => {
          if (validateUrlProtocol(item?.link)) {
            return (
              <Link
                to={item.link}
                target="_blank"
                key={index}
                className={style.application}
                rel="noopener noreferrer"
              >
                {/**TODO: Добавить отображение иконки, которое придет с бека, когда будет готово на беке*/}
                <SettingLogo className={style.icon} />
                <div className={style.linkTitle}>{item.title}</div>
              </Link>
            );
          }
          return null;
        })}
        <LinkBackPage to={routes.profile} />
      </>
    );
  };

  const renderError = () => {
    if (error.customError) {
      return (
        <div className={style.containerError}>
          <h3>Произошла ошибка при загрузке данных</h3>
          <LinkBackPage to={routes.profile} />
        </div>
      );
    }
  };

  return (
    <Page>
      <Title title={"Личный кабинет"} />
      <div className={style.content}>
        {renderLoader()}
        {renderContent()}
        {renderError()}
      </div>
    </Page>
  );
};
