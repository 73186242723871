import { useEffect } from "react";

import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { errorSlice } from "@/store/error";
import { ErrorMessage } from "@/components";
import { Button, FormFieldInput } from "@/ui";
import { zodResolver } from "@hookform/resolvers/zod";
import { editPhoneThunk, getUserState } from "@/store/user";
import { useAppDispatch } from "@/hooks/useAppDispatch.hook";
import { addPlusPhoneFormField } from "@/utils/addPlusPhoneFormField";
import { useFormErrorFromBackend } from "@/hooks/useFormErrorFromBackend";
import {
  formSchemaEditPhoneModal,
  FormSchemaEditPhoneModal,
} from "@/constants/validateSchema";

import style from "./EditModal.module.css";
import { Container, EditModalProps } from "./Container";

const defaultValuesForm: FormSchemaEditPhoneModal = {
  phone: "",
};

export const EditPhone = ({ onClose }: EditModalProps) => {
  const dispatch = useAppDispatch();
  const user = useSelector(getUserState);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting, isSubmitted, isDirty },
    setError,
  } = useForm<FormSchemaEditPhoneModal>({
    resolver: zodResolver(formSchemaEditPhoneModal),
    defaultValues: defaultValuesForm,
  });

  useFormErrorFromBackend<FormSchemaEditPhoneModal>({
    setError,
    fieldNames: ["phone"],
  });

  useEffect(() => {
    if (user.isPhoneChanged) {
      onClose();
    }
  }, [user.isPhoneChanged]);

  const onSubmitHandler = async (values: FormSchemaEditPhoneModal) => {
    dispatch(
      editPhoneThunk({
        data: addPlusPhoneFormField({ data: values })
          .data as FormSchemaEditPhoneModal, // TODO:fixed types
      }),
    );
  };

  return (
    <Container
      title={"Изменение Номера телефона"}
      onClose={onClose}
      hint={`Новый номер телефона будет необходимо его подтвердить. Текущий номер телефона: ${user.data.phone} `}
    >
      <form onSubmit={handleSubmit(onSubmitHandler)} className={style.form}>
        <FormFieldInput<FormSchemaEditPhoneModal>
          type="phone"
          name="phone"
          isRequiredField
          title={"Мобильный телефон"}
          control={control}
          register={register}
          error={errors.phone}
          placeholder={"Введите номер"}
        />

        <ErrorMessage />

        <div className={style.actions}>
          <Button
            className={style.btn}
            name={"Отмена"}
            type={"button"}
            onClick={onClose}
            secondary
          />
          <Button
            className={style.submit}
            name={"Подтвердить"}
            disabled={
              isSubmitting ||
              !isDirty ||
              (isSubmitted && !isValid && !isDirty) ||
              user.editing
            }
            type={"submit"}
            isLoading={user.editing}
            onClick={() => {
              dispatch(errorSlice.actions.resetError());
            }}
          />
        </div>
      </form>
    </Container>
  );
};
