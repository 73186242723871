import { useEffect } from "react";

import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { errorSlice } from "@/store/error";
import { Button, FormFieldInput } from "@/ui";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAppDispatch } from "@/hooks/useAppDispatch.hook";
import { ErrorMessage, GetRequestCodeAgain } from "@/components";
import { useFormErrorFromBackend } from "@/hooks/useFormErrorFromBackend";
import {
  confirmEmailByCodeThunk,
  getConfirmCodeEmailThunk,
  getUserState,
} from "@/store/user";
import {
  formSchemaConfirmEmailByCodeModal,
  FormSchemaConfirmEmailByCodeModal,
} from "@/constants/validateSchema";

import style from "./ConfirmModal.module.css";
import { ConfirmModalProps, Container } from "./Container";
import { useResendCodeTimer } from "@/pages/twoFAPage/hooks";

const defaultValuesForm: FormSchemaConfirmEmailByCodeModal = {
  code: "",
};

export const ConfirmEmail = ({ onClose }: ConfirmModalProps) => {
  const dispatch = useAppDispatch();
  const user = useSelector(getUserState);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting, isSubmitted, isDirty },
    setError,
  } = useForm<FormSchemaConfirmEmailByCodeModal>({
    resolver: zodResolver(formSchemaConfirmEmailByCodeModal),
    defaultValues: defaultValuesForm,
  });

  useFormErrorFromBackend<FormSchemaConfirmEmailByCodeModal>({
    setError,
    fieldNames: ["code"],
  });

  useEffect(() => {
    if (user.data.email_verified && !user.editing) {
      onClose();
    }
  }, [user.data.email_verified, user.editing]);

  const onSubmitHandler = async (values: FormSchemaConfirmEmailByCodeModal) => {
    dispatch(
      confirmEmailByCodeThunk({
        data: values,
      }),
    );
  };

  const getOtpCodeAgainCallback = () => {
    dispatch(errorSlice.actions.resetError());

    dispatch(getConfirmCodeEmailThunk());
  };

  const { handleClick: onGetOtpCodeAgain } = useResendCodeTimer(
    60000,
    getOtpCodeAgainCallback,
    [],
  );

  useEffect(() => {
    onGetOtpCodeAgain();
  }, []);

  return (
    <Container
      title={"Подтверждение e-mail"}
      isLoading={user.editing}
      onClose={onClose}
      hint={`На указанный e-mail адрес было отправлено письмо с кодом подтверждения. Введите код в поле и подтвердите e-mail адрес.`}
    >
      <form onSubmit={handleSubmit(onSubmitHandler)} className={style.form}>
        <FormFieldInput<FormSchemaConfirmEmailByCodeModal>
          type="input"
          name="code"
          isRequiredField
          title={"Код подтверждения"}
          control={control}
          register={register}
          error={errors.code}
          placeholder={"Введите код"}
          autoFocus
          maxLength={6}
        />

        <GetRequestCodeAgain
          handleClickButton={onGetOtpCodeAgain}
          receiver={user.otpEmail}
          type="email"
        />

        <ErrorMessage />

        <Button
          className={style.submit}
          name={"Подтвердить"}
          disabled={
            isSubmitting ||
            !isDirty ||
            (isSubmitted && !isValid && !isDirty) ||
            user.editing
          }
          type={"submit"}
          isLoading={user.editing}
          onClick={() => {
            dispatch(errorSlice.actions.resetError());
          }}
        />
      </form>
    </Container>
  );
};
