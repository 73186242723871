import { NavigateFunction } from "react-router";

import { routes } from "@/pages/routes";
import { errorSlice } from "@/store/error";
import { store } from "@/store/configureStore";
import { logoutUrl } from "@/endpoints/apiUrl";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { CustomResponse } from "@/services/apiFetch.type";
import { showNotification } from "@/services/notification";
import { apiFetch, callApiFn, FetchError } from "@/services/apiFetch";

export const logoutThunk = createAsyncThunk(
  "auth/logout",
  async (navigate: NavigateFunction) => {
    try {
      const response = await callApiFn<CustomResponse<undefined>>(() =>
        apiFetch({
          url: `${logoutUrl}`,
          options: {
            method: "POST",
          },
        }),
      );

      // data в ответе всегда пустая
      if (response.success) {
        localStorage.removeItem("access_token");
        navigate(routes.login);
        return true;
      } else {
        showNotification("AuthLogoutError");
        throw response;
      }
    } catch (e: FetchError | any) {
      store.dispatch(errorSlice.actions.setCustomError(e.errors));
    }
  },
);
