import { showNotification } from "@/services/notification";
import { useState, useEffect, useRef, useCallback } from "react";

export function useResendCodeTimer(
  duration = 60000,
  callback: (args?: unknown) => void,
  callbackDeps: unknown[],
) {
  const [remainingTime, setRemainingTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const timerRef = useRef<any>(null);
  const startTimeRef = useRef<any>(null);

  const startTimer = useCallback(() => {
    setIsRunning(true);
    startTimeRef.current = Date.now();
    setRemainingTime(duration);

    timerRef.current = setInterval(() => {
      const elapsed = Date.now() - startTimeRef.current;
      const newRemainingTime = Math.max(duration - elapsed, 0);
      setRemainingTime(newRemainingTime);

      if (newRemainingTime === 0) {
        clearInterval(timerRef.current);
        setIsRunning(false);
      }
    }, 1000);
  }, [duration]);

  const handleClick = useCallback(() => {
    if (isRunning) {
      showNotification("Custom", {
        type: "warning",
        content: `PIN-код уже выслан на ваш почтовый адрес.<br />Если вы не можете найти письмо проверьте папку спам или свяжись с администраторами id.prodamus.ru.<br />Повторная отправка доступна через ${Math.ceil(remainingTime / 1000)} сек.`,
        autoClose: 10000,
        style: { width: 400 },
      });
    } else {
      startTimer();
      callback();
    }
  }, [isRunning, remainingTime, startTimer, ...callbackDeps]);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, []);

  return { handleClick, remainingTime, isRunning };
}
