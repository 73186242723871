import { allowedOrigins, routes } from "@/pages/routes";

export function isValidOauthAuthorizeUrl(url: string): boolean {
  try {
    const currentUrl = new URL(url);
    return (
      allowedOrigins.includes(currentUrl.origin) &&
      currentUrl.pathname === routes.oauthAuthorize
    );
  } catch {
    return false;
  }
}
