import { PayloadAction } from "@reduxjs/toolkit";
import { showNotification } from "@/services/notification";

import { IApplication, UserState } from "../type";

export const getApplicationsThunkReducer = {
  pending: (state: UserState) => {
    state.loading = true;
  },
  fulfilled: (
    state: UserState,
    action: PayloadAction<IApplication[] | undefined>,
  ) => {
    if (action.payload) {
      state.listApplications = action.payload;
    }
    state.loading = false;
  },
  rejected: (state: UserState) => {
    state.loading = false;

    showNotification("GlobalError");
  },
};
