import { ReactNode, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch } from "@/hooks/useAppDispatch.hook";
import { useSelector } from "react-redux";
import { ModalTypes, getModalState, modalSlice } from "@/store/modal";
import { getUserState } from "@/store/user";
import { errorSlice } from "@/store/error";
import {
  ConfirmEmail,
  ConfirmPhone,
  EditEmail,
  EditPassword,
  EditPhone,
} from "@/pages/profile/components";

const isValidModalType = (type: any): type is ModalTypes => {
  return Object.values(ModalTypes).includes(type);
};

/** Компонент-для отображения модальных окон:
 * - При получении user.data и если в сторе есть modalType, то открывается соотвествущие модльное окно
 * - При открытии модального окна, добавляется modalType в URL
 * - При закрытии модального окна, удаляется modalType из стора и из URL
 */

export const ModalLauncher = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useAppDispatch();
  const modal = useSelector(getModalState);
  const user = useSelector(getUserState);

  const [isReadyRenderModal, setSsReadyRenderModal] = useState(false);

  const modalType = modal.modalType;

  useEffect(() => {
    if (user.data) {
      const searchParams = new URLSearchParams(location.search);
      if (modalType && isValidModalType(modalType)) {
        // Добавление modalType в URL
        searchParams.set("modalType", modalType);

        navigate(`${location.pathname}?${searchParams.toString()}`, {
          replace: true,
        });

        setSsReadyRenderModal(true);
      } else {
        // Удаление modalType из URL
        searchParams.delete("modalType");

        navigate(`${location.pathname}?${searchParams.toString()}`, {
          replace: true,
        });
      }
    }
  }, [modal.modalType, user.data]);

  const handleCloseModal = () => {
    dispatch(errorSlice.actions.resetError());
    dispatch(modalSlice.actions.setModalType(null));
  };

  const renderModal = (type: any) => {
    switch (type) {
      case ModalTypes.ConfirmPhone:
        return <ConfirmPhone onClose={handleCloseModal} />;
      case ModalTypes.ConfirmEmail:
        return <ConfirmEmail onClose={handleCloseModal} />;
      case ModalTypes.EditPassword:
        return <EditPassword onClose={handleCloseModal} />;
      case ModalTypes.EditPhone:
        return <EditPhone onClose={handleCloseModal} />;
      case ModalTypes.EditEmail:
        return <EditEmail onClose={handleCloseModal} />;

      default: {
        return null;
      }
    }
  };

  return isReadyRenderModal ? renderModal(modalType) : null;
};
