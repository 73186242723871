import { ErrorObject, apiFetch, callApiFn } from "@/services/apiFetch";
import { CustomResponse } from "@/services/apiFetch.type";
import { useState } from "react";

interface UseFetchOptions {
  method?: "GET" | "POST" | "PUT" | "DELETE";
  headers?: HeadersInit;
  body?: BodyInit | null;
}

type TFetch<FetchBody> = (body?: FetchBody) => Promise<1 | 0>;

interface UseFetchState<TResponseData, FetchBody> {
  data: TResponseData | null;
  error: Error | null | ErrorObject;
  isLoading: boolean;
  fetch: TFetch<FetchBody>;
}

function useFetch<TResponseData = unknown, FetchBody = unknown>(
  url: string,
  options?: UseFetchOptions,
): UseFetchState<TResponseData, FetchBody> {
  const [data, setData] = useState<TResponseData | null>(null);
  const [error, setError] = useState<ErrorObject | Error | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetch: TFetch<FetchBody> = async bodyRequest => {
    let updatedBody: null | undefined | BodyInit | FetchBody = options?.body;

    if (bodyRequest) {
      updatedBody = bodyRequest;
    }

    setIsLoading(true);
    try {
      const response = await callApiFn<CustomResponse<TResponseData>>(() =>
        apiFetch({
          url,
          options: {
            ...options,
            body: updatedBody ? JSON.stringify(updatedBody) : null,
          },
        }),
      );

      if (!response.success) {
        setError(response?.errors);
        return 0;
      }
      setData(response.data);
      setError(null);
      return 1;
      // TODO: Продумать логику ошибок
    } catch (error) {
      if (error instanceof Error) {
        setError(error);
        return 0;
      }
      return 0;
    } finally {
      setIsLoading(false);
    }
  };

  return { data, error, isLoading, fetch };
}

export default useFetch;
