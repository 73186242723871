import { errorSlice } from "@/store/error";
import { store } from "@/store/configureStore";
import { otpEmailUrl } from "@/endpoints/apiUrl";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { CustomResponse } from "@/services/apiFetch.type";
import { apiFetch, callApiFn, FetchError } from "@/services/apiFetch";
import { IGetConfirmCodeEmailResponseBody } from "@/store/auth";

export const getConfirmCodeEmailThunk = createAsyncThunk(
  "user/getConfirmCodeEmail",
  async () => {
    try {
      const response = await callApiFn<
        CustomResponse<IGetConfirmCodeEmailResponseBody>
      >(() =>
        apiFetch({
          url: `${otpEmailUrl}`,
          options: {
            method: "POST",
          },
        }),
      );

      if (response.success) {
        return response.data.email;
      } else {
        throw response;
      }
    } catch (e: FetchError | any) {
      store.dispatch(errorSlice.actions.setCustomError(e.errors));
    }
  },
);
