import { PayloadAction } from "@reduxjs/toolkit";
import { AuthState, IAuth } from "../type";

export const otpConfirmThunkReducer = {
  pending: (state: AuthState) => {
    state.loading = true;
  },
  fulfilled: (state: AuthState, action: PayloadAction<IAuth | undefined>) => {
    if (action.payload) {
      state.data = action.payload;
      state.isAuth = true;

      if (action.payload?.access_token) {
        localStorage.setItem("access_token", action.payload.access_token);
      }
      if (action.payload?.trust_token) {
        localStorage.setItem("trust_token", action.payload.trust_token);
      }
    }
    state.loading = false;
  },
  rejected: (state: AuthState) => {
    state.loading = false;
  },
};
