import { combineReducers, Store } from "redux";
import { configureStore } from "@reduxjs/toolkit";

import { xcrfTokenMiddleware } from "@/middlewares";

import { ErrorState, errorSlice } from "@/store/error";
import { AuthState, authSlice } from "@/store/auth";
import { UserState, userSlice } from "@/store/user";
import { ModalState, modalSlice } from "@/store/modal";

export interface ApplicationStore {
  auth: AuthState;
  user: UserState;
  error: ErrorState;
  modal: ModalState;
}

export const store = configureStore({
  reducer: combineReducers<ApplicationStore>({
    auth: authSlice.reducer,
    error: errorSlice.reducer,
    user: userSlice.reducer,
    modal: modalSlice.reducer,
  }),
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(xcrfTokenMiddleware),
});

export function createApplicationStore(): Store<ApplicationStore> {
  return store;
}
