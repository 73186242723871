import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  FormSchemaMasqueradePage,
  formSchemaMasqueradePage,
} from "@/constants/validateSchema";
import { useNavigate } from "react-router";
import { useAppDispatch } from "@/hooks/useAppDispatch.hook";
import { Button, FormFieldInput } from "@/ui";
import { errorSlice } from "@/store/error";
import {
  Title,
  Page,
  StyledForm,
  LinkBackPage,
  ErrorMessage,
} from "@/components";

import style from "./MasqueradePage.module.css";
import { routes } from "../routes";
import { getAuthState, masqueradeThunk } from "@/store/auth";
import { useSelector } from "react-redux";

export const MasqueradePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const userIdFromParams = searchParams.get("user_id");
  const auth = useSelector(getAuthState);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors, isValid, isSubmitting, isSubmitted, isDirty },
  } = useForm<FormSchemaMasqueradePage>({
    resolver: zodResolver(formSchemaMasqueradePage),
    defaultValues: {
      user_id: userIdFromParams ? userIdFromParams : "",
    },
  });

  useEffect(() => {
    if (userIdFromParams && auth.isAuthCheckDone) {
      handleMasquerade({ user_id: userIdFromParams });
    }
  }, [userIdFromParams]);

  const onSubmitHandler: SubmitHandler<FormSchemaMasqueradePage> = data => {
    handleMasquerade(data);
  };

  const handleMasquerade = async (data: FormSchemaMasqueradePage) => {
    dispatch(masqueradeThunk({ data, navigate }));
  };

  return (
    <Page>
      <Title title={"Замаскироваться под пользователем по User ID"} />
      <StyledForm handleSubmit={handleSubmit(onSubmitHandler)}>
        <FormFieldInput<FormSchemaMasqueradePage>
          type="input"
          name="user_id"
          isRequiredField
          title={"ID пользователя"}
          control={control}
          register={register}
          error={errors.user_id}
          placeholder={"Введите Id"}
          autoFocus
        />

        <ErrorMessage />

        <Button
          isLoading={auth.isLoadingMasquerade}
          disabled={
            isSubmitting ||
            (isSubmitted && !isValid && !isDirty) ||
            auth.isLoadingMasquerade
          }
          className={style.submit}
          name={"Замаскироваться"}
          type={"submit"}
          onClick={() => {
            dispatch(errorSlice.actions.resetError());
          }}
        />

        <LinkBackPage to={routes.profile} label="Перейти на профиль" />
      </StyledForm>
    </Page>
  );
};
