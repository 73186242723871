import React from "react";

import { Header } from "../header";
import { Footer } from "../footer";

import style from "./Layout.module.css";

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
  return (
    <div className={style.container}>
      <Header />

      <div className={style.main}>{children}</div>
      <Footer />
    </div>
  );
};
