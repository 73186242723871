import { ToastOptions } from "react-toastify";

export interface NotificationValue extends ToastOptions {
  type: "success" | "info" | "warning" | "error";
  content: string;
}

export type INotificationConfig = Record<NotificationKeys, NotificationValue>;

export type NotificationKeys =
  | "СaptchaCheckIsRobot"
  | "СaptchaTokenPending"
  | "AuthCheckStatusError"
  | "AuthCsrfTokenExpired"
  | "AuthCsrfTokenInvalid"
  | "ChangeEmailError"
  | "ChangePhoneError"
  | "ChangePasswordError"
  | "AuthLogoutError"
  | "AuthMissingDataError"
  | "CopyToClipboardSuccess"
  | "CopyToClipboardError"
  | "СonfirmPhoneSuccess"
  | "СonfirmEmailSuccess"
  | "СhangePhoneSuccess"
  | "СhangeEmailSuccess"
  | "СhangePasswordSuccess"
  | "GlobalError"
  | "InvalidRedirectUrl"
  | "InvalidOriginUrl"
  | "GlobalCaptchaError";

export const NotificationConfig: INotificationConfig = {
  // Success
  CopyToClipboardSuccess: {
    type: "success",
    content: `Данные успешно скопированы в буфер обмена`,
  },
  СonfirmPhoneSuccess: {
    type: "success",
    content: `Номер телефона успешно подтвержден`,
    style: { width: 360 },
  },
  СonfirmEmailSuccess: {
    type: "success",
    content: `E-mail адрес успешно подтвержден`,
    style: { width: 330 },
  },
  СhangePhoneSuccess: {
    type: "success",
    content: `Номер телефона успешно изменен`,
    style: { width: 330 },
  },
  СhangeEmailSuccess: {
    type: "success",
    content: `E-mail адрес успешно изменен`,
    style: { width: 290 },
  },
  СhangePasswordSuccess: {
    type: "success",
    content: `Пароль успешно изменен`,
    style: { width: 270 },
  },

  // Warning
  СaptchaCheckIsRobot: {
    type: "warning",
    content: `Проверяем, что вы не робот.<br /> Пожалуйста подождите.`,
  },
  СaptchaTokenPending: {
    type: "warning",
    content: `Не все загрузилось.<br /> Пожалуйста подождите и попробуйте снова`,
    style: { width: 400 },
  },

  // Error
  AuthCheckStatusError: {
    type: "error",
    content: `Ошибка при получении статуса авторизации`,
    style: { width: 400 },
  },
  AuthLogoutError: {
    type: "error",
    content: `Ошибка при выходе из аккаунта`,
  },
  AuthMissingDataError: {
    type: "error",
    content: `Ошибка. Не хватает данных`,
  },
  GlobalError: {
    type: "error",
    content: `Произошла ошибка. Обновите страницу`,
    autoClose: false,
    style: { width: 360 },
  },
  GlobalCaptchaError: {
    type: "error",
    content: `Произошла ошибка с капчей. Обновите страницу`,
    autoClose: false,
  },
  AuthCsrfTokenExpired: {
    type: "error",
    content: `Срок действия токена истек. Перезагрузите страницу`,
    autoClose: false,
  },
  AuthCsrfTokenInvalid: {
    type: "error",
    content: `Ваш токен доступа недействителен. Пожалуйста авторизируйтесь заново`,
  },
  ChangePasswordError: {
    type: "error",
    content: `Не удалось изменить пароль`,
  },
  ChangeEmailError: {
    type: "error",
    content: `Не удалось изменить E-mail адрес`,
    style: { width: 330 },
  },
  ChangePhoneError: {
    type: "error",
    content: `Не удалось изменить номер телефона`,
    style: { width: 360 },
  },
  CopyToClipboardError: {
    type: "error",
    content: `Не удалось скопировать данные в буфер обмена`,
  },
  InvalidRedirectUrl: {
    type: "error",
    content: `Не валидный URL для перехода`,
    style: { width: 400 },
  },
  InvalidOriginUrl: {
    type: "error",
    content: `Неподдерживаемый источник`,
    style: { width: 400 },
  },
};
