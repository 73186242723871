import { useEffect } from "react";

import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { routes } from "@/pages/routes";
import { errorSlice } from "@/store/error";
import { Button, FormFieldInput } from "@/ui";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAppDispatch } from "@/hooks/useAppDispatch.hook";
import { getAuthState, resetPasswordRequestThunk } from "@/store/auth";
import { useFormErrorFromBackend } from "@/hooks/useFormErrorFromBackend";
import {
  ErrorMessage,
  LinkBackPage,
  Page,
  StyledForm,
  Title,
} from "@/components";
import {
  formSchemaForgotPasswordPage,
  FormSchemaForgotPasswordPage,
} from "@/constants/validateSchema";

import style from "./ForgotPasswordPage.module.css";
import { validateUrlOrigin } from "@/utils/validateUrlOrigin";

const defaultValuesForm: FormSchemaForgotPasswordPage = {
  email: "",
  recovery_url: "",
};

export const ForgotPasswordPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const auth = useSelector(getAuthState);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting, isSubmitted, isDirty },
    setError,
  } = useForm<FormSchemaForgotPasswordPage>({
    resolver: zodResolver(formSchemaForgotPasswordPage),
    defaultValues: defaultValuesForm,
  });

  useFormErrorFromBackend<FormSchemaForgotPasswordPage>({
    setError,
    fieldNames: ["email"],
  });

  const onSubmitHandler = async (val: FormSchemaForgotPasswordPage) => {
    if (validateUrlOrigin()) {
      val.recovery_url = `${window.location.origin}${routes.confirmForgotPassword}`;

      dispatch(
        resetPasswordRequestThunk({
          data: val,
          navigate,
        }),
      );
    }
  };

  useEffect(() => {
    dispatch(errorSlice.actions.resetError());
  }, []);

  return (
    <Page>
      <Title title={"Восстановление пароля"} />
      <StyledForm handleSubmit={handleSubmit(onSubmitHandler)}>
        <FormFieldInput<FormSchemaForgotPasswordPage>
          type="input"
          name="email"
          isRequiredField
          title={"E-mail"}
          control={control}
          register={register}
          error={errors.email}
          placeholder={"Введите e-mail"}
          autoFocus
        />

        <ErrorMessage />
        <Button
          className={style.submit}
          name={"ВОССТАНОВИТЬ ПАРОЛЬ"}
          disabled={
            isSubmitting ||
            !isDirty ||
            (isSubmitted && !isValid && !isDirty) ||
            auth.loading
          }
          isLoading={auth.loading}
          type={"submit"}
          onClick={() => {
            dispatch(errorSlice.actions.resetError());
          }}
        />

        <div className={style.other}>
          <span>Вспомнили пароль? </span>
          <LinkBackPage to={routes.login} />
        </div>
      </StyledForm>
    </Page>
  );
};
