import { PayloadAction } from "@reduxjs/toolkit";

import { AuthState, IAuth } from "../type";

export const masqueradeThunkReducer = {
  pending: (state: AuthState) => {
    state.isLoadingMasquerade = true;
  },
  fulfilled: (state: AuthState, action: PayloadAction<IAuth | undefined>) => {
    if (action.payload) {
      // state.data = action.payload;
    }
    state.isLoadingMasquerade = false;
  },
  rejected: (state: AuthState) => {
    state.isLoadingMasquerade = false;
  },
};
