import { PayloadAction } from "@reduxjs/toolkit";
import { showNotification } from "@/services/notification";

import { AuthState } from "../type";

export const resetPasswordConfirmThunkReducer = {
  pending: (state: AuthState) => {
    state.loading = true;
  },
  fulfilled: (state: AuthState, action: PayloadAction<boolean | undefined>) => {
    if (action.payload) {
      showNotification("СhangePasswordSuccess");
    }
    state.loading = false;
  },
  rejected: (state: AuthState) => {
    showNotification("GlobalError");

    state.loading = false;
  },
};
