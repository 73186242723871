import { PayloadAction } from "@reduxjs/toolkit";

import { UserState, IUser } from "../type";

export const getUserThunkReducer = {
  pending: (state: UserState) => {
    state.loading = true;
  },
  fulfilled: (state: UserState, action: PayloadAction<IUser | undefined>) => {
    if (action.payload) {
      state.data = action.payload;
    }
    state.loading = false;
  },
  rejected: (state: UserState) => {
    state.loading = false;
  },
};
