import { cloneElement } from "react";
import { Controller } from "react-hook-form";

import { InputNumberProps, InputProps } from "antd";
import { UseFormRegisterReturn } from "react-hook-form";

import { FormSchemaCommonFields } from "@/constants/validateSchema";
import { PasswordProps, SearchProps, TextAreaProps } from "antd/es/input";

import { configInputs, InputFieldProps } from "./InputField";

export type GetInputComponentByTypeProps<T extends FormSchemaCommonFields> =
  Omit<InputFieldProps<T>, "register"> & {
    register: UseFormRegisterReturn<string>;
  };

export function getInputComponentByType<T extends FormSchemaCommonFields>({
  register,
  isRequiredField,
  ...props
}: GetInputComponentByTypeProps<T>) {
  const Component = configInputs[props.type];

  if (Component) {
    return (
      <Controller
        name={props.name}
        control={props.control}
        rules={{ required: isRequiredField }}
        render={({ field }) => {
          let mergedProps: any = { ...props, ...register, ...field }; // TODO: fixed any type

          if (props.type === "phone") {
            mergedProps = {
              ...props,
              onChange: field.onChange,
              register,
            };
          }

          return cloneElement(Component, mergedProps);
        }}
      />
    );
  }
}

export type InputAntdCommonProps = InputProps &
  InputNumberProps &
  PasswordProps &
  SearchProps &
  TextAreaProps;
