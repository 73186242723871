import { useMemo } from "react";
import { useSelector } from "react-redux";

import { getErrorState } from "@/store/error";
import { Path, UseFormSetError } from "react-hook-form";
import { FormSchemaCommonFields } from "@/constants/validateSchema";

interface UseFormErrorFromBackend<T extends FormSchemaCommonFields> {
  setError: UseFormSetError<T>;
  fieldNames: Array<Path<T>>;
}

export function useFormErrorFromBackend<T extends FormSchemaCommonFields>({
  setError,
  fieldNames,
}: UseFormErrorFromBackend<T>) {
  const errors = useSelector(getErrorState);

  useMemo(() => {
    fieldNames.forEach(fieldName => {
      const error = errors.customError?.fields?.[fieldName];
      if (error && error.length > 0) {
        setError(fieldName, { type: "custom", message: "" });
      }
    });
  }, [errors]);
}
