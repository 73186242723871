import { useSelector } from "react-redux";

import { getErrorState } from "@/store/error";

import style from "./ErrorMessage.module.css";
import { filterError } from "./utils/filterError";

export const ErrorMessage = () => {
  const error = useSelector(getErrorState);

  if (error.customError) {
    const listErrors = error.customError.message.split("\n");
    const filteredListErrors = filterError(listErrors);

    if (filteredListErrors.length === 0) {
      return null;
    }

    return (
      <div className={style.container}>
        <ul className={style.list}>
          {filteredListErrors.map((error, index) => {
            return <li key={index}>{error}</li>;
          })}
        </ul>
      </div>
    );
  }

  return <></>;
};
