import { PayloadAction } from "@reduxjs/toolkit";

import {
  AuthState,
  IOAuthPrepareData,
  OAuthAuthorizePrepareReturn,
} from "../type";

export const oauthAuthorizePrepareThunkReducer = {
  pending: (state: AuthState) => {
    state.isLoadingOauthPrepare = true;
  },
  fulfilled: (
    state: AuthState,
    action: PayloadAction<OAuthAuthorizePrepareReturn>,
  ) => {
    if (action.payload) {
      if (
        "isPendingOauthRedirect" in action.payload &&
        action.payload.isPendingOauthRedirect
      ) {
        state.isPendingOauthRedirect = true;
        return;
      }
      state.oauthPrepareData = action.payload as IOAuthPrepareData;
    }
    state.isLoadingOauthPrepare = false;
  },
  rejected: (state: AuthState) => {
    state.isLoadingOauthPrepare = false;
  },
};

export const oauthAuthorizeDenyThunkReducer = {
  pending: (state: AuthState) => {
    state.oauthPrepareData = null;
    state.isLoadingOauthActionDeny = true;
    state.isPendingOauthRedirect = true;
  },
  fulfilled: (state: AuthState) => {
    state.isLoadingOauthActionDeny = false;
    state.isPendingOauthRedirect = true;
  },
  rejected: (state: AuthState) => {
    state.isLoadingOauthActionDeny = false;
    state.isPendingOauthRedirect = true;
  },
};

export const oauthAuthorizeApproveThunkReducer = {
  pending: (state: AuthState) => {
    state.isLoadingOauthActionApprove = true;
  },
  fulfilled: (state: AuthState) => {
    state.oauthPrepareData = null;
    state.isLoadingOauthActionApprove = false;
    state.isPendingOauthRedirect = true;
  },
  rejected: (state: AuthState) => {
    state.isLoadingOauthActionApprove = false;
  },
};
