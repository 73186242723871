import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  confirmEmailByCodeThunk,
  editPasswordThunk,
  getApplicationsThunk,
  getUserThunk,
  getConfirmCodeEmailThunk,
  editEmailThunk,
  editPhoneThunk,
  confirmPhoneByCodeThunk,
  getConfirmCodePhoneThunk,
  IUser,
  UserState,
  getUserThunkReducer,
  editPasswordThunkReducer,
  editEmailThunkReducer,
  editPhoneThunkReducer,
  getApplicationsThunkReducer,
  confirmEmailByCodeThunkReducer,
  getConfirmCodeEmailThunkReducer,
  confirmPhoneByCodeThunkReducer,
  getConfirmCodePhoneThunkReducer,
} from ".";

const state: UserState = {
  data: {} as IUser,
  loading: false,
  editing: false,
  listApplications: null,
  isPasswordChanged: false, // Флаг, что пароль был изменен
  isEmailChanged: false, // Флаг, что почта была изменена
  isPhoneChanged: false, // Флаг, что телефон был изменен
  otpPhone: null,
  otpEmail: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState: state,
  reducers: {
    setIsPasswordChanged: (state, action: PayloadAction<boolean>) => {
      state.isPasswordChanged = action.payload;
    },
    setIsPhoneChanged: (state, action: PayloadAction<boolean>) => {
      state.isPhoneChanged = action.payload;
    },
    setIsEmailChanged: (state, action: PayloadAction<boolean>) => {
      state.isEmailChanged = action.payload;
    },
  },
  extraReducers: builder => {
    // Запрос данных пользователя
    builder.addCase(getUserThunk.pending, getUserThunkReducer.pending);
    builder.addCase(getUserThunk.fulfilled, getUserThunkReducer.fulfilled);
    builder.addCase(getUserThunk.rejected, getUserThunkReducer.rejected);

    // Изменение пароля
    builder.addCase(
      editPasswordThunk.pending,
      editPasswordThunkReducer.pending,
    );
    builder.addCase(
      editPasswordThunk.fulfilled,
      editPasswordThunkReducer.fulfilled,
    );
    builder.addCase(
      editPasswordThunk.rejected,
      editPasswordThunkReducer.rejected,
    );

    // Изменение email
    builder.addCase(editEmailThunk.pending, editEmailThunkReducer.pending);
    builder.addCase(editEmailThunk.fulfilled, editEmailThunkReducer.fulfilled);
    builder.addCase(editEmailThunk.rejected, editEmailThunkReducer.rejected);

    // Изменение phone
    builder.addCase(editPhoneThunk.pending, editPhoneThunkReducer.pending);
    builder.addCase(editPhoneThunk.fulfilled, editPhoneThunkReducer.fulfilled);
    builder.addCase(editPhoneThunk.rejected, editPhoneThunkReducer.rejected);

    // Список доступных приложений
    builder.addCase(
      getApplicationsThunk.pending,
      getApplicationsThunkReducer.pending,
    );
    builder.addCase(
      getApplicationsThunk.fulfilled,
      getApplicationsThunkReducer.fulfilled,
    );
    builder.addCase(
      getApplicationsThunk.rejected,
      getApplicationsThunkReducer.rejected,
    );

    // Подтверждение почты: отправить код подтверждения
    builder.addCase(
      confirmEmailByCodeThunk.pending,
      confirmEmailByCodeThunkReducer.pending,
    );
    builder.addCase(
      confirmEmailByCodeThunk.fulfilled,
      confirmEmailByCodeThunkReducer.fulfilled,
    );
    builder.addCase(
      confirmEmailByCodeThunk.rejected,
      confirmEmailByCodeThunkReducer.rejected,
    );

    // Подтверждение почты: запросить код подтверждения
    builder.addCase(
      getConfirmCodeEmailThunk.pending,
      getConfirmCodeEmailThunkReducer.pending,
    );
    builder.addCase(
      getConfirmCodeEmailThunk.fulfilled,
      getConfirmCodeEmailThunkReducer.fulfilled,
    );
    builder.addCase(
      getConfirmCodeEmailThunk.rejected,
      getConfirmCodeEmailThunkReducer.rejected,
    );

    // Подтверждение phone: отправить код подтверждения
    builder.addCase(
      confirmPhoneByCodeThunk.pending,
      confirmPhoneByCodeThunkReducer.pending,
    );
    builder.addCase(
      confirmPhoneByCodeThunk.fulfilled,
      confirmPhoneByCodeThunkReducer.fulfilled,
    );
    builder.addCase(
      confirmPhoneByCodeThunk.rejected,
      confirmPhoneByCodeThunkReducer.rejected,
    );

    // Подтверждение phone: запросить код подтверждения
    builder.addCase(
      getConfirmCodePhoneThunk.pending,
      getConfirmCodePhoneThunkReducer.pending,
    );
    builder.addCase(
      getConfirmCodePhoneThunk.fulfilled,
      getConfirmCodePhoneThunkReducer.fulfilled,
    );
    builder.addCase(
      getConfirmCodePhoneThunk.rejected,
      getConfirmCodePhoneThunkReducer.rejected,
    );
  },
});
