import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  AuthState,
  loginThunk,
  logoutThunk,
  otpConfirmThunk,
  otpRequestThunk,
  registrationThunk,
  resetPasswordConfirmThunk,
  resetPasswordRequestThunk,
  twoFAThunk,
  loginThunkReducer,
  logoutThunkReducer,
  registrationThunkReducer,
  twoFAThunkReducer,
  resetPasswordRequestThunkReducer,
  resetPasswordConfirmThunkReducer,
  otpRequestThunkReducer,
  otpConfirmThunkReducer,
  masqueradeThunk,
  masqueradeThunkReducer,
  oauthAuthorizePrepareThunk,
  oauthAuthorizePrepareThunkReducer,
  oauthAuthorizeDenyThunk,
  oauthAuthorizeDenyThunkReducer,
  oauthAuthorizeApproveThunk,
  oauthAuthorizeApproveThunkReducer,
} from ".";

const state: AuthState = {
  data: null,
  loading: false,
  isAuth: false,
  captchaToken: null,
  otpEmail: null,
  otpLoading: false,
  isAuthCheckDone: false,
  isLoadingMasquerade: false,
  oauthPrepareData: null,
  isLoadingOauthPrepare: false,
  isLoadingOauthActionApprove: false,
  isLoadingOauthActionDeny: false,
  isPendingOauthRedirect: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState: state,
  reducers: {
    setCaptchaToken: (state, action: PayloadAction<string | null>) => {
      state.captchaToken = action.payload;
    },
    authCheckDone: state => {
      state.isAuthCheckDone = true;
    },
  },
  extraReducers: builder => {
    // регистрация
    builder.addCase(
      registrationThunk.pending,
      registrationThunkReducer.pending,
    );
    builder.addCase(
      registrationThunk.fulfilled,
      registrationThunkReducer.fulfilled,
    );
    builder.addCase(
      registrationThunk.rejected,
      registrationThunkReducer.rejected,
    );

    // вход
    builder.addCase(loginThunk.pending, loginThunkReducer.pending);
    builder.addCase(loginThunk.fulfilled, loginThunkReducer.fulfilled);
    builder.addCase(loginThunk.rejected, loginThunkReducer.rejected);

    // выход
    builder.addCase(logoutThunk.pending, logoutThunkReducer.pending);
    builder.addCase(logoutThunk.fulfilled, logoutThunkReducer.fulfilled);
    builder.addCase(logoutThunk.rejected, logoutThunkReducer.rejected);

    // запрос на отправку кода 2fa
    builder.addCase(twoFAThunk.pending, twoFAThunkReducer.pending);
    builder.addCase(twoFAThunk.fulfilled, twoFAThunkReducer.fulfilled);
    builder.addCase(twoFAThunk.rejected, twoFAThunkReducer.rejected);

    // запрос на отправку кода входа на почту
    builder.addCase(otpRequestThunk.pending, otpRequestThunkReducer.pending);
    builder.addCase(
      otpRequestThunk.fulfilled,
      otpRequestThunkReducer.fulfilled,
    );
    builder.addCase(otpRequestThunk.rejected, otpRequestThunkReducer.rejected);

    // отправка кода входа на почту
    builder.addCase(otpConfirmThunk.pending, otpConfirmThunkReducer.pending);
    builder.addCase(
      otpConfirmThunk.fulfilled,
      otpConfirmThunkReducer.fulfilled,
    );
    builder.addCase(otpConfirmThunk.rejected, otpConfirmThunkReducer.rejected);

    // Восстановление пароля: запросить по email
    builder.addCase(
      resetPasswordRequestThunk.pending,
      resetPasswordRequestThunkReducer.pending,
    );
    builder.addCase(
      resetPasswordRequestThunk.fulfilled,
      resetPasswordRequestThunkReducer.fulfilled,
    );
    builder.addCase(
      resetPasswordRequestThunk.rejected,
      resetPasswordRequestThunkReducer.rejected,
    );

    // Восстановление пароля: установить новый пароль
    builder.addCase(
      resetPasswordConfirmThunk.pending,
      resetPasswordConfirmThunkReducer.pending,
    );
    builder.addCase(
      resetPasswordConfirmThunk.fulfilled,
      resetPasswordConfirmThunkReducer.fulfilled,
    );
    builder.addCase(
      resetPasswordConfirmThunk.rejected,
      resetPasswordConfirmThunkReducer.rejected,
    );
    // Маскировка под другого пользователя по user_id
    builder.addCase(masqueradeThunk.pending, masqueradeThunkReducer.pending);
    builder.addCase(
      masqueradeThunk.fulfilled,
      masqueradeThunkReducer.fulfilled,
    );
    builder.addCase(masqueradeThunk.rejected, masqueradeThunkReducer.rejected);

    builder.addCase(
      oauthAuthorizePrepareThunk.pending,
      oauthAuthorizePrepareThunkReducer.pending,
    );
    builder.addCase(
      oauthAuthorizePrepareThunk.fulfilled,
      oauthAuthorizePrepareThunkReducer.fulfilled,
    );
    builder.addCase(
      oauthAuthorizePrepareThunk.rejected,
      oauthAuthorizePrepareThunkReducer.rejected,
    );

    builder.addCase(
      oauthAuthorizeDenyThunk.pending,
      oauthAuthorizeDenyThunkReducer.pending,
    );
    builder.addCase(
      oauthAuthorizeDenyThunk.fulfilled,
      oauthAuthorizeDenyThunkReducer.fulfilled,
    );
    builder.addCase(
      oauthAuthorizeDenyThunk.rejected,
      oauthAuthorizeDenyThunkReducer.rejected,
    );

    builder.addCase(
      oauthAuthorizeApproveThunk.pending,
      oauthAuthorizeApproveThunkReducer.pending,
    );
    builder.addCase(
      oauthAuthorizeApproveThunk.fulfilled,
      oauthAuthorizeApproveThunkReducer.fulfilled,
    );
    builder.addCase(
      oauthAuthorizeApproveThunk.rejected,
      oauthAuthorizeApproveThunkReducer.rejected,
    );
  },
});
