import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ModalState, ModalTypeState } from ".";

const state: ModalState = {
  modalType: null,
};

export const modalSlice = createSlice({
  name: "modal",
  initialState: state,
  reducers: {
    setModalType: (state, action: PayloadAction<ModalTypeState>) => {
      state.modalType = action.payload;
    },
  },
});
