import { IAuth } from "@/store/auth";
import { errorSlice } from "@/store/error";
import { store } from "@/store/configureStore";
import { otpConfirmUrl } from "@/endpoints/apiUrl";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { CustomResponse } from "@/services/apiFetch.type";
import { FormSchemaTwoFAPage } from "@/constants/validateSchema";
import { apiFetch, callApiFn, FetchError } from "@/services/apiFetch";
import { redirectToStoredUrlOrProfile } from "@/utils/redirectOauthAuthorizeHelper";
import { NavigateFunction } from "react-router";

export const otpConfirmThunk = createAsyncThunk(
  "auth/otpConfirm",
  async ({
    data,
    navigate,
  }: {
    data: FormSchemaTwoFAPage;
    navigate: NavigateFunction;
  }) => {
    try {
      const response = await callApiFn<CustomResponse<IAuth>>(() =>
        apiFetch({
          url: `${otpConfirmUrl}`,
          options: {
            method: "POST",
            body: JSON.stringify({ code: data.code }),
            headers: {
              Authorization: "Bearer " + data.two_fa_token,
            },
          },
        }),
      );

      if (response.success) {
        redirectToStoredUrlOrProfile(navigate);
        return response.data;
      } else {
        throw response;
      }
    } catch (e: FetchError | any) {
      store.dispatch(errorSlice.actions.setCustomError(e.errors));
    }
  },
);
