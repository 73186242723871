import { PayloadAction } from "@reduxjs/toolkit";

import { AuthState, IAuth } from "../type";

export const loginThunkReducer = {
  pending: (state: AuthState) => {
    state.loading = true;
  },
  fulfilled: (state: AuthState, action: PayloadAction<IAuth | undefined>) => {
    if (action.payload) {
      state.data = action.payload;

      if (action.payload?.two_fa_required === false) {
        state.isAuth = true;
      }
    }
    state.loading = false;
  },
  rejected: (state: AuthState) => {
    state.loading = false;
  },
};
