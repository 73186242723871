import { PayloadAction } from "@reduxjs/toolkit";

import { AuthState, IResponseRegistration } from "../type";

export const registrationThunkReducer = {
  pending: (state: AuthState) => {
    state.loading = true;
    state.captchaToken = null;
  },
  fulfilled: (
    state: AuthState,
    action: PayloadAction<IResponseRegistration | undefined>,
  ) => {
    if (action.payload) {
      state.data = action.payload;
    }
    state.loading = false;
  },
  rejected: (state: AuthState) => {
    state.loading = false;
  },
};
