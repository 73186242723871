import { PayloadAction } from "@reduxjs/toolkit";
import { showNotification } from "@/services/notification";

import { UserState } from "../type";

export const editPasswordThunkReducer = {
  pending: (state: UserState) => {
    state.editing = true;
  },
  fulfilled: (state: UserState, action: PayloadAction<boolean | undefined>) => {
    if (action.payload) {
      showNotification("СhangePasswordSuccess");

      state.isPasswordChanged = true;
    }
    state.editing = false;
  },
  rejected: (state: UserState) => {
    showNotification("ChangePasswordError");

    state.editing = false;
  },
};
