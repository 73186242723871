import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorState, IError, TError } from "@/store/error";
import { FetchError } from "@/services/apiFetch";

export const errorSlice = createSlice({
  name: "error",
  initialState: {} as ErrorState,
  reducers: {
    setGlobalError: (state, action: PayloadAction<IError>) => {
      state.globalError = {
        errors: action.payload.errors,
        status: action.payload.status,
      };
      state.hasError = true;
    },
    setCustomError: (state, action: PayloadAction<FetchError>) => {
      if (action.payload.code !== "unauthenticated") {
        state.customError = action.payload;
        state.hasError = true;
      }
    },
    setValidationError: (state, action: PayloadAction<TError>) => {
      state.validationError = {
        ...state.validationError,
        ...Object.entries(action.payload).reduce((acc, [field, messages]) => {
          const existingMessages = state.validationError?.[field] || [];
          acc[field] = [...new Set([...existingMessages, ...messages])];
          return acc;
        }, {} as TError),
      };
      state.hasError = true;
    },

    clearValidation: (state, action: PayloadAction<string | undefined>) => {
      const fieldName = action.payload;
      if (fieldName) {
        state.validationError = {
          ...state.validationError,
          [fieldName]: [],
        };
      } else {
        state.validationError = undefined;
      }
    },
    resetError: state => {
      state.globalError = undefined;
      state.customError = undefined;
      state.hasError = false;
    },
  },
});
