import { errorSlice } from "@/store/error";
import { store } from "@/store/configureStore";
import { editPhoneUrl } from "@/endpoints/apiUrl";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { CustomResponse } from "@/services/apiFetch.type";
import { apiFetch, callApiFn, FetchError } from "@/services/apiFetch";
import { FormSchemaEditPhoneModal } from "@/constants/validateSchema";

export const editPhoneThunk = createAsyncThunk(
  "user/editPhone",
  async ({ data }: { data: FormSchemaEditPhoneModal }) => {
    try {
      const response = await callApiFn<
        CustomResponse<FormSchemaEditPhoneModal>
      >(() =>
        apiFetch({
          url: `${editPhoneUrl}`,
          options: {
            method: "POST",
            body: JSON.stringify(data),
          },
        }),
      );

      if (response.success) {
        return data;
      } else {
        throw response;
      }
    } catch (e: FetchError | any) {
      store.dispatch(errorSlice.actions.setCustomError(e.errors));
    }
  },
);
