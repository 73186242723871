import { PayloadAction } from "@reduxjs/toolkit";
import { showNotification } from "@/services/notification";
import { FormSchemaEditPhoneModal } from "@/constants/validateSchema";

import { UserState } from "../type";

export const editPhoneThunkReducer = {
  pending: (state: UserState) => {
    state.editing = true;
  },
  fulfilled: (
    state: UserState,
    action: PayloadAction<FormSchemaEditPhoneModal | undefined>,
  ) => {
    if (action.payload) {
      showNotification("СhangePhoneSuccess");

      state.isPhoneChanged = true;
      state.data.phone = action.payload.phone;
      state.data.phone_verified = 0;
    }
    state.editing = false;
  },
  rejected: (state: UserState) => {
    showNotification("ChangePhoneError");

    state.editing = false;
  },
};
