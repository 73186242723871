export const routes = {
  registration: "/registration",
  login: "/login",
  forgotPassword: "/forgot-password",
  resetLinkSent: "/forgot-password/reset-link",
  confirmForgotPassword: "/forgot-password/confirm",
  otpRequest: "/otp/request",
  twoFA: "/2fa",
  error: "/error",
  profile: "/user/profile",
  applications: "/applications",
  masquerade: "/masquerade",
  oauthAuthorize: "/oauth/authorize",
};

export const authorizedCommonRoutes = [routes.login, routes.registration];

export const unchechAuthRoutes = [
  routes.twoFA,
  routes.forgotPassword,
  routes.confirmForgotPassword,
  routes.resetLinkSent,
  routes.error,
  routes.otpRequest,
];

export const allowedPaths = Object.values(routes);

export const allowedOrigins = import.meta.env.VITE_ALLOWED_ORIGINS.split(",");
