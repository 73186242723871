import { PayloadAction } from "@reduxjs/toolkit";
import { showNotification } from "@/services/notification";
import { FormSchemaEditEmailModal } from "@/constants/validateSchema";

import { UserState } from "../type";

export const editEmailThunkReducer = {
  pending: (state: UserState) => {
    state.editing = true;
  },
  fulfilled: (
    state: UserState,
    action: PayloadAction<FormSchemaEditEmailModal | undefined>,
  ) => {
    if (action.payload) {
      showNotification("СhangeEmailSuccess");

      state.isEmailChanged = true;
      state.data.email = action.payload.email;
      state.data.email_verified = 0;
    }
    state.editing = false;
  },
  rejected: (state: UserState) => {
    showNotification("ChangeEmailError");

    state.editing = false;
  },
};
