import PhoneInput from "react-phone-input-2";

import ru from "react-phone-input-2/lang/ru.json";

import "react-phone-input-2/lib/style.css";

import "./PhoneField.css";

interface PhoneFieldProps {
  error?: any;
  disabled?: boolean;
  className?: string;
}

export const PhoneField = ({
  name,
  error,
  onChange,
  autoFocus,
  className,
  disabled = false,
}: any) => {
  const hasError = error && error.type;

  return (
    <div className={className}>
      <PhoneInput
        placeholder="Введите номер"
        inputProps={{
          name: name,
          autoFocus: autoFocus,
        }}
        onChange={value => onChange(value)}
        disabled={disabled}
        localization={ru}
        country="ru"
        specialLabel={""}
        areaCodes={{ kz: [7, 6] }}
        inputStyle={{
          backgroundColor: disabled
            ? "rgba(0, 0, 0, 0.04) !important"
            : "white !important",
        }}
        inputClass={
          hasError
            ? "input-phone input-phone-custom has-error-phone"
            : "input-phone input-phone-custom"
        }
        containerStyle={{
          border: hasError
            ? "1px solid #E41D36"
            : "1px solid rgb(230, 230, 230) !important",
          borderRadius: 6,
          borderBottomLeftRadius: 3,
          borderTopLeftRadius: 3,
        }}
      />
    </div>
  );
};
