import { showNotification } from "@/services/notification";

import { AuthState } from "../type";

export const resetPasswordRequestThunkReducer = {
  pending: (state: AuthState) => {
    state.loading = true;
  },
  fulfilled: (state: AuthState) => {
    state.loading = false;
  },
  rejected: (state: AuthState) => {
    showNotification("GlobalError");

    state.loading = false;
  },
};
