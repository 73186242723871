import { NavigateFunction } from "react-router";

type Props = {
  data: any; // TODO: fixed any types
  navigate?: NavigateFunction;
};

export const addPlusPhoneFormField = (val: Props): Props => {
  const modifiedVal = {
    ...val,
    data: {
      ...val.data,
      // Добавляем "+" к phone, если это необходимо
      ...(val.data?.phone && {
        phone: val.data.phone.startsWith("+")
          ? val.data.phone
          : `+${val.data.phone}`,
      }),
    },
  };

  return modifiedVal;
};
