import { useEffect } from "react";
import style from "./EditModal.module.css";
import { Button, FormFieldInput } from "@/ui";
import { useAppDispatch } from "@/hooks/useAppDispatch.hook";
import { useSelector } from "react-redux";
import { errorSlice } from "@/store/error";
import { getUserState, editEmailThunk } from "@/store/user";
import { ErrorMessage } from "@/components";
import { Container, EditModalProps } from "./Container";
import {
  FormSchemaEditEmailModal,
  formSchemaEditEmailModal,
} from "@/constants/validateSchema";
import { useFormErrorFromBackend } from "@/hooks/useFormErrorFromBackend";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

const defaultValuesForm: FormSchemaEditEmailModal = {
  email: "",
};

export const EditEmail = ({ onClose }: EditModalProps) => {
  const dispatch = useAppDispatch();
  const user = useSelector(getUserState);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting, isSubmitted, isDirty },
    setError,
  } = useForm<FormSchemaEditEmailModal>({
    resolver: zodResolver(formSchemaEditEmailModal),
    defaultValues: defaultValuesForm,
  });

  useFormErrorFromBackend<FormSchemaEditEmailModal>({
    setError,
    fieldNames: ["email"],
  });

  useEffect(() => {
    if (user.isEmailChanged) {
      onClose();
    }
  }, [user.isEmailChanged]);

  const onSubmitHandler = async (values: FormSchemaEditEmailModal) => {
    dispatch(editEmailThunk({ data: values }));
  };

  return (
    <Container
      title={"Изменение E-mail"}
      onClose={onClose}
      hint={`После сохранения нового E-mail адреса будет необходимо его подтвердить. Текущий E-mail: ${user.data.email} `}
    >
      <form onSubmit={handleSubmit(onSubmitHandler)} className={style.form}>
        <FormFieldInput<FormSchemaEditEmailModal>
          type="input"
          name="email"
          isRequiredField
          title={"Новый E-mail"}
          control={control}
          register={register}
          error={errors.email}
          placeholder={"Введите e-mail"}
          autoComplete={"email"}
          autoFocus
        />

        <ErrorMessage />

        <div className={style.actions}>
          <Button
            className={style.btn}
            name={"Отмена"}
            type={"button"}
            onClick={onClose}
            secondary
          />
          <Button
            className={style.submit}
            name={"Подтвердить"}
            disabled={
              isSubmitting ||
              !isDirty ||
              (isSubmitted && !isValid && !isDirty) ||
              user.editing
            }
            type={"submit"}
            isLoading={user.editing}
            onClick={() => {
              dispatch(errorSlice.actions.resetError());
            }}
          />
        </div>
      </form>
    </Container>
  );
};
