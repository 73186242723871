import { PayloadAction } from "@reduxjs/toolkit";

import { AuthState } from "../type";

export const twoFAThunkReducer = {
  pending: (state: AuthState) => {
    state.otpLoading = true;
  },
  fulfilled: (state: AuthState, action: PayloadAction<string | undefined>) => {
    state.otpLoading = false;

    if (action.payload) {
      state.otpEmail = action.payload;
    }
  },
  rejected: (state: AuthState) => {
    state.otpLoading = false;
  },
};
