import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { Button } from "@/ui";
import { ErrorMessage, Page, Title } from "@/components";
import { errorSlice, getErrorState } from "@/store/error";
import { URL_TELEGRAM } from "@/components/footer/Footer";
import { useAppDispatch } from "@/hooks/useAppDispatch.hook";
import { getAuthState, oauthAuthorizePrepareThunk } from "@/store/auth";
import {
  oauthAuthorizeApproveThunk,
  oauthAuthorizeDenyThunk,
} from "@/store/auth/oauthAuthorize/oauthAuthorizeThunk.action";

import style from "./OauthAuthorizePage.module.css";

export const OauthAuthorizePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const auth = useSelector(getAuthState);
  const error = useSelector(getErrorState);

  const queryParams = searchParams.toString();

  useEffect(() => {
    if (auth.isAuthCheckDone) {
      dispatch(oauthAuthorizePrepareThunk({ queryParams }));
    }
  }, []);

  const handleApprove = () => {
    if (auth.oauthPrepareData) {
      dispatch(oauthAuthorizeApproveThunk({ data: auth.oauthPrepareData }));
    }
  };

  const handleDeny = () => {
    if (auth.oauthPrepareData) {
      dispatch(
        oauthAuthorizeDenyThunk({ data: auth.oauthPrepareData, navigate }),
      );
    }
  };

  return (
    <Page isLoading={auth.isLoadingOauthPrepare || auth.isPendingOauthRedirect}>
      {auth.oauthPrepareData ? (
        <>
          <Title
            title={`Приложение ${auth.oauthPrepareData.client_name} запрашивает доступ к`}
          />
          <div className={style.listContainer}>
            <ul className={style.listScopes}>
              {auth.oauthPrepareData.scopes &&
                auth.oauthPrepareData.scopes.map((scope, index) => {
                  return <li key={index}>{scope.description}</li>;
                })}
            </ul>

            <ErrorMessage />

            {error?.customError && error.customError?.message ? (
              <a
                href={URL_TELEGRAM}
                target="_blank"
                rel="noopener noreferrer"
                className={style.supportTelegram}
              >
                Обратиться в службу поддержки
              </a>
            ) : (
              <div className={style.containerButtons}>
                <Button
                  isLoading={auth.isLoadingOauthActionApprove}
                  disabled={
                    auth.isLoadingOauthActionDeny ||
                    auth.isLoadingOauthActionApprove
                  }
                  className={style.btn}
                  name={"Разрешить"}
                  type={"submit"}
                  onClick={() => {
                    dispatch(errorSlice.actions.resetError());
                    handleApprove();
                  }}
                />

                <Button
                  isLoading={auth.isLoadingOauthActionDeny}
                  disabled={
                    auth.isLoadingOauthActionDeny ||
                    auth.isLoadingOauthActionApprove
                  }
                  className={style.btn}
                  name={"Запретить"}
                  onClick={() => {
                    dispatch(errorSlice.actions.resetError());
                    handleDeny();
                  }}
                  ghost
                />
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <Title
            title={`Произошла ошибка. Данные для запроса доступа не найдены`}
          />

          <div className={style.listContainer}>
            <a
              href={URL_TELEGRAM}
              target="_blank"
              rel="noopener noreferrer"
              className={style.supportTelegram}
            >
              Обратиться в службу поддержки
            </a>
          </div>
        </>
      )}
    </Page>
  );
};
