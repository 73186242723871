import { PayloadAction } from "@reduxjs/toolkit";
import { showNotification } from "@/services/notification";

import { UserState } from "../type";

export const confirmPhoneByCodeThunkReducer = {
  pending: (state: UserState) => {
    state.editing = true;
  },
  fulfilled: (state: UserState, action: PayloadAction<boolean | undefined>) => {
    if (action.payload) {
      showNotification("СonfirmPhoneSuccess");

      state.data.phone_verified = 1;
    }

    state.editing = false;
  },
  rejected: (state: UserState) => {
    showNotification("GlobalError");

    state.editing = false;
  },
};
